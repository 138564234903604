import styled from 'styled-components'
import { position, color, space, layout, border, flexbox } from 'styled-system'

const Container = styled.div.withConfig({ displayName: 'Container' })`
  ${border}
  ${position}
  ${flexbox}
  ${layout}
  ${color}
  ${space}
`

export default Container
