import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Alert } from 'shared/components'
import IconButton from '@material-ui/core/IconButton'
import Destroy from '@material-ui/icons/Delete'
import Tooltip from '@material-ui/core/Tooltip'

const styles = {
  iconColorPrimary: {
    color: 'var(--novo-dark-gray)'
  }
}
const DestroyModifierGroup = ({ destroy, classes, group }) => {
  const [alertOpen, setAlertOpen] = useState(false)

  return (
    <>
      <IconButton
        aria-label="mute selection"
        classes={{
          colorPrimary: classes.iconColorPrimary
        }}
        color="primary"
        onClick={() => setAlertOpen(true)}
      >
        <Tooltip title="destroy modifier group">
          <Destroy />
        </Tooltip>
      </IconButton>

      <Alert
        open={alertOpen}
        cancel={() => setAlertOpen(false)}
        message={`Delete ${group.category.displayName} and selections?`}
        title={'Confirm'}
        confirm={() => {
          destroy(group)
          setAlertOpen(false)
        }}
      />
    </>
  )
}

export default withStyles(styles)(DestroyModifierGroup)
