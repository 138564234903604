import React, { useState } from 'react'
import { GET_MENUS } from 'pages/menu/graphql/queries/menu'
import { contains, ifNil, compareUsingKey } from 'utils'
import { Space } from 'shared/components'
import { decode } from 'utils/graphql'
import { useQuery } from 'react-apollo'
import { useConceptLocationsContext } from 'shared/hooks'
import get from 'lodash.get'
import v from 'voca'
import EditModifierGroup from 'pages/menu/components/review/EditModifierGroup'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'

/*
 *   Takes modifier groups, returns UI for:
 *     - CRUD'ing components by menu
 *     - editing prompting order of modifier groups
 */

const ModifierGroupsManager = ({ modifierGroups, element, menuToModifierGroups }) => {
  const { activeConceptId } = useConceptLocationsContext()
  const [activeMenu, setActiveMenu] = useState('all')

  const { data, error: menusError } = useQuery(GET_MENUS, {
    variables: {
      concept: activeConceptId
    },
    fetchPolicy: 'network-only'
  })

  if (menusError) throw new Error(menusError)

  return (
    <div>
      {data && data.menus && (
        <FormControl variant="standard">
          <InputLabel id="menus">Filter Modifier Groups By Menu</InputLabel>
          <Select
            value={activeMenu}
            onChange={e => setActiveMenu(e.target.value)}
            style={{ minWidth: 320 }}
          >
            <MenuItem value="all">All Menus</MenuItem>
            {data.menus.map(m => (
              <MenuItem value={m.id} key={m.id}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {v.titleCase(m.name)}
                  <Space direction="x" value="half" />
                  <span style={{ color: 'var(--novo-light-gray)' }}>
                    {` - #${decode(m.id).id}`}
                  </span>
                </div>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      <Space direction="y" value="two" />

      {activeMenu === 'all'
        ? modifierGroups
            .sort((a, b) => compareUsingKey(a, b, 'category.displayName'))
            .map(g => (
              <EditModifierGroup
                menus={ifNil(get(data, 'menus'), [])}
                menuToModifierGroups={menuToModifierGroups}
                key={g.componentId}
                categoryComponent={g}
                element={element}
              />
            ))
        : modifierGroups
            .filter(g => contains(g.menus, activeMenu, m => m.id))
            .sort((a, b) => compareUsingKey(a, b, 'category.displayName'))
            .map(g => (
              <EditModifierGroup
                menus={ifNil(get(data, 'menus'), [])}
                menuToModifierGroups={menuToModifierGroups}
                key={g.componentId}
                categoryComponent={g}
                element={element}
              />
            ))}
    </div>
  )
}

export default ModifierGroupsManager
