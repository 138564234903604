import React from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import ErrorMessage from 'pages/menu/components/ErrorMessage'
import DuplicateModifierGroup from 'pages/menu/components/review/DuplicateModifierGroup'
import EditModifierGroupMenus from 'pages/menu/components/review/EditModifierGroupMenus'
import Space from 'shared/components/Space'
import Tooltip from '@material-ui/core/Tooltip'
import Chip from '@material-ui/core/Chip'
import { Field, connect } from 'formik'
import { decode } from 'utils/graphql'
import {
  allTrue,
  notNil,
  compareUsingKey,
  contains,
  titleCase,
  compareNums
} from 'utils'
import get from 'lodash.get'
import cloneDeep from 'lodash.clonedeep'
import EditSelections from 'pages/menu/components/EditSelections'
import DestroyModifierGroup from 'pages/menu/components/review/DestroyModifierGroup'

/**---------- styles and styled components ----------*/
const Root = styled.div.withConfig({ displayName: 'EditModifierGroup' })`
  margin-bottom: 32px;
`

/**---------- main component ----------*/
const EditModifierGroup = ({
  categoryComponent,
  element,
  menus,
  menuToModifierGroups,
  formik: { values, errors, setFieldValue, touched }
}) => {
  const formikIndex = values.modifierGroups.indexOf(categoryComponent)
  const maxSelectionCount = get(
    values,
    `modifierGroups[${formikIndex}].maxSelectionCount`
  )
  const minSelectionCount = get(
    values,
    `modifierGroups[${formikIndex}].minSelectionCount`
  )
  const { category } = categoryComponent
  const fieldString = `modifierGroups[${formikIndex}]`

  const allSelections = (() => {
    const all = []

    values.modifierGroups.forEach((g, idx) => {
      g.selections.forEach(s =>
        all.push({ ...s, modifierGroup: { ...g, formikIndex: idx } })
      )
    })

    return all.sort((a, b) => compareUsingKey(a, b, 'element.displayName'))
  })()

  return (
    <Root>
      <div
        style={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <div>
          <Typography variant="body1" style={{ fontFamily: 'Pangram Medium' }}>
            {category.displayName}
            {'  '}
            <span
              style={{
                color: 'var(--novo-light-gray)',
                fontFamily: 'Pangram Regular'
              }}
            >
              {`#${get(category, 'categoryId')} - (${get(category, 'lookupName')})`}
            </span>
          </Typography>
        </div>

        <Space direction="x" value="quarter" />

        <EditSelections
          allSelections={allSelections}
          categoryComponent={categoryComponent}
          element={element}
          updateOrder={order =>
            setFieldValue(
              `modifierGroups[${formikIndex}].proseHint.readbackOrder`,
              order
            )
          }
          setFieldValue={setFieldValue}
          errors={errors}
          formikIndex={formikIndex}
          values={values}
        />

        <Space direction="x" value="quarter" />

        <DuplicateModifierGroup
          modifierGroup={categoryComponent}
          menuToModifierGroups={menuToModifierGroups}
          menus={menus}
        />

        <Space direction="x" value="quarter" />

        <DestroyModifierGroup
          destroy={group => {
            const newGroups = Array.from(cloneDeep(values.modifierGroups))
            const newAntiElements = { ...values.antiElements }

            const idHitList = group.selections.map(s => s.element.elementId)

            idHitList.forEach(id => {
              // if there is only one modifier group with this selection, delete the anti-elements entry
              if (
                newGroups.filter(g =>
                  g.selections.find(s => s.element.elementId === id)
                ).length === 1
              ) {
                delete newAntiElements[id]
                for (const elementId in newAntiElements) {
                  if (newAntiElements[elementId].indexOf(id) > -1) {
                    newAntiElements[elementId].splice(
                      newAntiElements[elementId].indexOf(id),
                      1
                    )
                  }
                }
              }
            })

            const currentPromptingOrder = values.promptingOrder

            newGroups.splice(formikIndex, 1)

            const newPromptingOrder = currentPromptingOrder.filter(id =>
              contains(newGroups, id, g => g.category.categoryId)
            )

            setFieldValue('promptingOrder', newPromptingOrder)
            setFieldValue('antiElements', newAntiElements)
            setFieldValue('modifierGroups', newGroups)
          }}
          group={categoryComponent}
        />
      </div>

      {notNil(get(errors, fieldString.concat('addDefault'))) && (
        <div style={{ width: 280 }}>
          <Space direction="x" value="one" />
          <ErrorMessage
            message={get(errors, fieldString.concat('addDefault'))}
          />
          <Space direction="x" value="half" />
        </div>
      )}

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="body2">Menus:</Typography>
        {categoryComponent.menus
          .map(m => decode(m.id).id)
          .sort(compareNums)
          .map(id => (
            <React.Fragment key={id}>
              <Space direction="x" value="half" />
              <Tooltip
                title={titleCase(
                  get(
                    menus.find(menu => decode(menu.id).id === id),
                    'name'
                  )
                )}
              >
                <Chip
                  label={`#${id}`}
                  style={{ color: 'var(--novo-dark-gray)' }}
                  variant="outlined"
                />
              </Tooltip>
            </React.Fragment>
          ))}
        <Space direction="x" value="half" />

        <EditModifierGroupMenus
          modifierGroup={categoryComponent}
          menuToModifierGroups={menuToModifierGroups}
          menus={menus}
          submit={menus => {
            setFieldValue(`modifierGroups[${formikIndex}].menus`, menus)
          }}
        />
      </div>

      {get(errors, `modifierGroups[${formikIndex}].menus`) && (
        <ErrorMessage
          message={get(errors, `modifierGroups[${formikIndex}].menus`)}
        />
      )}

      <Space direction="y" value="half" />
      <Space direction="y" value="eighth" />

      <Field
        name={`modifierGroups[${formikIndex}].minQty`}
        validate={minQty => {
          const maxQty = get(values, `modifierGroups[${formikIndex}].maxQty`)
          if (parseInt(minQty, 10) > parseInt(maxQty, 10))
            return 'min quantity should be less than or equal to max quantity'
        }}
      >
        {({ field, form: { errors, touched } }) => (
          <>
            <TextField
              label="min qty"
              inputProps={{ type: 'number', min: '0' }}
              style={{
                display: 'block'
              }}
              {...field}
            />
            {allTrue(
              obj => notNil(get(obj, `modifierGroups[${formikIndex}].minQty`)),
              [errors, touched]
            ) && (
              <ErrorMessage
                message={get(errors, `modifierGroups[${formikIndex}].minQty`)}
              />
            )}
          </>
        )}
      </Field>

      <Space direction="y" value="half" />

      <Field
        name={`modifierGroups[${formikIndex}].maxQty`}
        validate={maxQty => {
          const minQty = get(values, `modifierGroups[${formikIndex}].minQty`)
          if (parseInt(minQty, 10) > parseInt(maxQty, 10))
            return 'max quantity should be greater than or equal to minimium quantity'
        }}
      >
        {({ field, form: { values, errors, touched } }) => (
          <>
            <TextField
              label="max qty"
              inputProps={{ type: 'number', min: '0' }}
              style={{
                display: 'block'
              }}
              {...field}
            />
            {allTrue(
              obj => notNil(get(obj, `modifierGroups[${formikIndex}].maxQty`)),
              [errors, touched]
            ) && (
              <ErrorMessage
                message={get(errors, `modifierGroups[${formikIndex}].maxQty`)}
              />
            )}
          </>
        )}
      </Field>

      <Space direction="y" value="half" />

      <Field
        name={`modifierGroups[${formikIndex}].minSelectionCount`}
        validate={() => {
          if (parseInt(minSelectionCount, 10) > parseInt(maxSelectionCount, 10))
            return 'min selection count should be less than or equal to max selection count'
          else if (minSelectionCount === '' && maxSelectionCount !== '')
            return 'a minimum selection count is required when there is a maximum selection count'
        }}
      >
        {({ field, form: { errors, touched } }) => (
          <>
            <TextField
              label="min total selection count"
              inputProps={{ type: 'number', min: '0' }}
              style={{
                display: 'block'
              }}
              {...field}
            />
            {allTrue(
              obj =>
                notNil(
                  get(obj, `modifierGroups[${formikIndex}].minSelectionCount`)
                ),
              [errors, touched]
            ) && (
              <ErrorMessage
                message={get(
                  errors,
                  `modifierGroups[${formikIndex}].minSelectionCount`
                )}
              />
            )}
          </>
        )}
      </Field>

      <Space direction="y" value="half" />

      <Field
        name={`modifierGroups[${formikIndex}].maxSelectionCount`}
        validate={() => {
          if (parseInt(minSelectionCount, 10) > parseInt(maxSelectionCount, 10))
            return 'max selection count should be greater than or equal to minimium selection count'
          else if (minSelectionCount !== '' && maxSelectionCount === '')
            return 'a maximum selection count is required when there is a minimum selection count'
        }}
      >
        {({ field, form: { errors, touched } }) => (
          <>
            <TextField
              label="max total selection count"
              inputProps={{ type: 'number', min: '0' }}
              style={{
                display: 'block'
              }}
              {...field}
            />
            {allTrue(
              obj =>
                notNil(
                  get(obj, `modifierGroups[${formikIndex}].maxSelectionCount`)
                ),
              [errors, touched]
            ) && (
              <ErrorMessage
                message={get(
                  errors,
                  `modifierGroups[${formikIndex}].maxSelectionCount`
                )}
              />
            )}
          </>
        )}
      </Field>
    </Root>
  )
}

export default connect(EditModifierGroup)
