import React, { useState } from 'react'
import styled from 'styled-components'
import { array, func } from 'prop-types'
import Space from 'shared/components/Space'
import { contains, removeValue } from 'utils'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { MdDragHandle, MdClose } from 'react-icons/md'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import AddItemDialog from 'pages/menu/components/categories/AddItemDialog'

/**---------- styles and styled components ----------*/
const styles = { buttonCritical: { color: 'var(--color-utility-critical)' } }

const Root = styled.div.withConfig({ displayName: 'ElementDnd' })`
  min-height: 350px;
`

/**---------- util fns ----------*/
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const onDragEnd = (unsavedOrder, updateOrder, { destination, source }) => {
  if (!destination) return null

  updateOrder(reorder(unsavedOrder, source.index, destination.index))
}

const getElementId = e => e.elementId

/**---------- complementary components ----------*/
const OptionRaw = ({
  element: { elementId, displayName },
  index,
  classes,
  remove
}) => (
  <Draggable draggableId={elementId} index={index}>
    {(provided, snapshot) => {
      return (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <div style={{ display: 'flex', alignItems: 'center', maxWidth: 420 }}>
            <Typography variant="body2">{displayName}</Typography>
            <div
              style={{
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                justifyContent: 'flex-end',
                marginRight: 16
              }}
            >
              <IconButton
                color="primary"
                classes={{
                  colorPrimary: classes.buttonCritical
                }}
                onClick={() => remove(elementId)}
              >
                <MdClose />
              </IconButton>

              <Space direction="x" value="one" />

              <div {...provided.dragHandleProps}>
                <MdDragHandle />
              </div>
            </div>
          </div>
        </div>
      )
    }}
  </Draggable>
)

const Option = withStyles(styles)(OptionRaw)

/**---------- main component ----------*/
const ElementDnd = ({
  allOptions,
  initialOrder,
  updateOrder,
  unsavedOrder
}) => {
  const [addingReadbackElements, setAddingReadbackElements] = useState(false)
  const toggleEditingReadbackOrder = () =>
    setAddingReadbackElements(!addingReadbackElements)

  // ensure the element IDs contained in readback order exist in all options
  const verifiedElements = unsavedOrder.filter(id =>
    contains(allOptions, id, getElementId)
  )

  const readbackElements = verifiedElements.map((e, idx) => {
    const element = allOptions.find(o => o.elementId === e)

    // line below _should_ be unreachable, handling just in case
    if (!element)
      throw new Error(`error finding element ${e} in child elements`)

    return (
      <Option
        key={element.elementId}
        element={element}
        index={idx}
        remove={id => updateOrder(removeValue(unsavedOrder, id))}
      />
    )
  })

  return (
    <Root>
      <DragDropContext
        onDragEnd={result => onDragEnd(unsavedOrder, updateOrder, result)}
      >
        <AddItemDialog
          options={allOptions.filter(
            o => !contains(verifiedElements, o.elementId)
          )}
          open={addingReadbackElements}
          submit={ids => updateOrder([...unsavedOrder, ...ids])}
          toggleOpen={toggleEditingReadbackOrder}
        />

        <Droppable droppableId="readbackOrder">
          {(provided, snapshot) => {
            return (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                {readbackElements}
                {provided.placeholder}
              </div>
            )
          }}
        </Droppable>
      </DragDropContext>
    </Root>
  )
}

ElementDnd.propTypes = {
  allOptions: array.isRequired,
  currentOrder: array,
  unsavedOrder: array.isRequired,
  updateOrder: func.isRequired
}

export default withStyles(styles)(ElementDnd)
