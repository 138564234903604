import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import styled from 'styled-components'
import { useConceptLocationsContext } from 'shared/hooks'
import { useQuery } from 'react-apollo'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import { MdFilterList } from 'react-icons/md'
import Space from 'shared/components/Space'
import pluralize from 'pluralize'
import MenuManagementNavLinks from 'pages/menu/components/MenuManagementNavLinks'
import TranscriptConsoleNavLinks from 'pages/transcript/components/TranscriptConsoleNavLinks'
import ReportsNavLinks from 'shared/LocationNavigator/ReportsNavLinks'
import SelectLocations from 'shared/LocationNavigator/SelectLocations'
import GET_MENU_LOCATIONS from 'shared/graphql/queries/getMenuLocations'

/**---------- util fns ----------*/
const countLocations = (totalLocations, activeIds, mode) => {
  switch (mode) {
    case 'SHOW_ALL':
      return 'all'
    case 'SHOW_ONLY':
      return activeIds.length
    case 'EXCLUDE_LOCATIONS':
      return totalLocations - activeIds.length
    default:
      return 0
  }
}

/**---------- Root & other styled components ----------*/
const Root = styled.div.withConfig({
  displayName: 'LocationNavigator'
})`
  background-color: #4a5664;
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin-left: 80px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 24px;
  position: fixed;
  width: 185px;
`

/**---------- complementary components ----------*/
const ActiveConceptHeader = ({ name, classes }) => (
  <Typography
    variant="h6"
    color="primary"
    classes={{ colorPrimary: classes.typographyColorPrimary }}
  >
    {name}
  </Typography>
)

// component to filter locations for the current concept
const LocationsFilter = ({ classes, activeConcept }) => {
  const [selectingLocations, setSelectingLocations] = useState(false)

  const toggleSelectingLocations = () => {
    setSelectingLocations(!selectingLocations)
  }

  const { mode, ids } = useConceptLocationsContext()

  const { data, error, loading } = useQuery(GET_MENU_LOCATIONS, {
    variables: { conceptId: activeConcept.id },
    skip: !activeConcept
  })

  if (!activeConcept) return <div style={{ height: 48 }} />

  if (error)
    return <div style={{ height: 48, color: 'white' }}>locations error</div>

  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        minHeight: 48,
        paddingRight: 8
      }}
    >
      <Typography
        variant="body2"
        color="primary"
        classes={{
          colorPrimary: classes.typographyColorPrimary
        }}
      >
        {loading
          ? 'all locations'
          : mode === 'COHORTS'
          ? `${ids.length} ${pluralize('cohort', ids.length)}`
          : `${countLocations(
              data.menuLocations.length,
              ids,
              mode
            )} ${pluralize(
              'location',
              countLocations(data.menuLocations.length, ids, mode)
            )}`}
      </Typography>

      <IconButton
        aria-label="select locations"
        classes={{
          colorSecondary: classes.iconColorSecondary
        }}
        color="secondary"
        onClick={toggleSelectingLocations}
      >
        <MdFilterList />
      </IconButton>

      {selectingLocations && (
        <SelectLocations
          concept={activeConcept}
          closeSelectLocations={toggleSelectingLocations}
        />
      )}
    </div>
  )
}

/**---------- main component ----------*/

const LocationNavigator = ({
  match: {
    params: { section, conceptId: urlConceptId }
  },
  classes
}) => {
  const { activeConceptId, concepts } = useConceptLocationsContext()
  if (!concepts) return <Root />
  const activeConcept = concepts.find(c => activeConceptId === c.id)
  if (!activeConcept)
    return (
      <Root>
        <Typography
          variant="body2"
          color="primary"
          classes={{
            colorPrimary: classes.typographyColorPrimary
          }}
          style={{
            marginBottom: 36,
            textAlign: 'center'
          }}
        >
          please select a concept to get started...
        </Typography>
      </Root>
    )
  return (
    <Root>
      <ActiveConceptHeader name={activeConcept.name} classes={classes} />

      <Space value="half" />

      <LocationsFilter classes={classes} activeConcept={activeConcept} />

      <Space value="one" />

      <TranscriptConsoleNavLinks
        baseURL={`/c/${activeConcept.id}`}
        activeSection={section}
      />
    </Root>
  )
}
const muiStyles = () => ({
  drawerPaper: {
    backgroundColor: '#4A5664',
    display: 'flex',
    marginLeft: 80,
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 24,
    width: 185
  },
  typographyColorPrimary: {
    color: 'white'
  },
  iconColorSecondary: {
    color: 'var(--novo-light-gray)'
  }
})
export default withStyles(muiStyles)(LocationNavigator)
