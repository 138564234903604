import React, { Fragment } from 'react'
import { array } from 'prop-types'
import { compareUsingKey } from 'utils'
import styled from 'styled-components'
import ListSubheader from '@material-ui/core/ListSubheader'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

const MenuElementsList = ({ elements, updateActiveElement }) => {
  const elementsGroupedByFirstLetter = {}

  const sortElementsByDisplayName = array => {
    return array.sort((a, b) => compareUsingKey(a, b, 'displayName'))
  }

  // populate elementsGroupedByFirstLetter
  elements.forEach(e => {
    if (
      elementsGroupedByFirstLetter[`${e.displayName[0].toLowerCase()}`] ===
      undefined
    ) {
      elementsGroupedByFirstLetter[`${e.displayName[0].toLowerCase()}`] = [e]
    } else
      elementsGroupedByFirstLetter[`${e.displayName[0].toLowerCase()}`].push(e)
  })

  // sort the menu elements in each group alphabetically
  for (const letter in elementsGroupedByFirstLetter) {
    elementsGroupedByFirstLetter[`${letter}`] = sortElementsByDisplayName(
      elementsGroupedByFirstLetter[`${letter}`]
    )
  }

  const elementGroups = Object.keys(elementsGroupedByFirstLetter).sort()

  const sections = elementGroups.map(g => {
    const elements = elementsGroupedByFirstLetter[`${g}`].map(e => {
      const { category } = e

      return (
        <ListItem
          button
          onClick={() => updateActiveElement(e.elementId)}
          key={e.elementId}
        >
          <ListItemText
            primary={<div>{e.displayName}&ensp;<span style={{ color: "var(--novo-light-gray)" }}>#{e.elementId}</span></div>}
            secondary={`${category.displayName} ${
              category.lookupName === null ? '' : `(${category.lookupName})`
            }`}
          />
        </ListItem>
      )
    })

    return (
      <Fragment key={g}>
        <ListSubheader style={{ zIndex: 0 }}>{g.toUpperCase()}</ListSubheader>
        <Divider light style={{ margin: '0 16px' }} />
        {elements}
      </Fragment>
    )
  })

  return (
    <MenuElementsListRoot>
      <List disablePadding>{sections}</List>
    </MenuElementsListRoot>
  )
}

const MenuElementsListRoot = styled.div.withConfig({
  displayName: 'MenuElementsList'
})`
  margin: 0;
  padding: 0;
`

MenuElementsList.propTypes = {
  elements: array.isRequired
}

export default MenuElementsList
