import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { useMutation } from 'react-apollo'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import { TextField, CheckboxWithLabel, Submit } from 'shared/components/Formik'
import { Space } from 'shared/components'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import { CREATE_MENU } from 'pages/menu/graphql/mutations/menu'
import { GET_MENUS } from 'pages/menu/graphql/queries/menu'
import * as yup from 'yup'

const FormDialog = ({
  open,
  handleClose,
  handleSubmit,
  classes,
  concept
}) => {
  return (
    <Dialog
      classes={{
        paper: classes.paper,
        root: classes.dialogRoot,
        scrollPaper: classes.scrollPaper
      }}
      disableBackdropClick
      open={open}
      onClose={handleClose}
    >
      <DialogTitle style={{ textAlign: 'center' }}>Create New {concept.name} Menu</DialogTitle>

      <DialogContent>
        <Formik
          initialValues={{ name: '', default: false }}
          initialErrors={{ name: 'a menu name is required' }}
          onSubmit={handleSubmit}
          validationSchema={yup.object().shape({
            name: yup.string().required('a menu name is required')
          })}
        >
          <Form>
            <div>
              <Field
                component={TextField}
                name="name"
                fullWidth
                required
                label="menu name"
              />
              <ErrorMessage
                component="p"
                name="name"
                style={{ fontSize: '.75rem', color: 'crimson' }}
              />
            </div>

            <Space direction="y" value="one" />

            <Field
              type="checkbox"
              name="default"
              label="default menu"
              component={CheckboxWithLabel}
              checkboxProps={{ color: 'primary' }}
            />

            <Space direction="y" value="half" />

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                classes={{ label: classes.buttonLabel }}
                onClick={handleClose}
              >
                cancel
              </Button>

              <Space direction="x" value="one" />

              <Submit
                color="primary"
                title="create"
                classes={{ label: classes.buttonLabel }}
              />
            </div>
          </Form>
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

const StatusDialog = ({ classes, status, handleClose, open, concept }) => {
  return (
    <Dialog
      classes={{
        paper: classes.paper,
        root: classes.dialogRoot,
        scrollPaper: classes.scrollPaper
      }}
      disableBackdropClick
      open={open}
      onClose={handleClose}
    >
      <DialogTitle style={{ textAlign: 'center' }}>Create {concept.name} Menu</DialogTitle>

      {status.error && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Space direction="y" value="half" />
          <Typography>error while creating menu...</Typography>
          <Space direction="y" value="eighth" />
          <Typography style={{ color: 'crimson' }}>
            {status.error.message}
          </Typography>
          <Space direction="y" value="one" />
          <Button onClick={handleClose}>ok</Button>
          <Space direction="y" value="one" />
        </div>
      )}

      {status.loading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Space direction="y" value="two" />
          <CircularProgress size={24} />
          <Space direction="y" value="one" />
          <Typography>creating menu...</Typography>
          <Space direction="y" value="one" />
        </div>
      )}

      {status.data && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
          }}
        >
          <Space direction="y" value="one" />
          <Typography style={{ color: 'var(--novo-green)' }}>
            menu created successfully!
          </Typography>
          <Space direction="y" value="one" />
          <Button onClick={handleClose}>ok</Button>
          <Space direction="y" value="one" />
        </div>
      )}
    </Dialog>
  )
}

const CreateMenu = ({ classes, activeConceptId, concept }) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [statusDialogOpen, setStatusDialogOpen] = useState(false)

  const closeAll = () => {
    setStatusDialogOpen(false)
    setDialogOpen(false)
  }

  const handleClose = () => {
    setDialogOpen(false)
  }

  const [createMenu, status] = useMutation(CREATE_MENU, {
    refetchQueries: [
      {
        query: GET_MENUS,
        variables: {
          concept: activeConceptId
        }
      }
    ]
  })

  const handleSubmit = values => {
    createMenu({ variables: { ...values, concept: activeConceptId } })
    setStatusDialogOpen(true)
  }

  return (
    <>
      <Button
        variant="contained"
        onClick={() => setDialogOpen(true)}
        color="primary"
        classes={{ label: classes.buttonLabel, root: classes.buttonRoot }}
      >
        New Menu
      </Button>

      <StatusDialog
        status={status}
        concept={concept}
        handleClose={closeAll}
        classes={classes}
        open={statusDialogOpen}
      />

      {!statusDialogOpen && (
        <FormDialog
          concept={concept}
          classes={classes}
          open={dialogOpen}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
        />
      )}
    </>
  )
}

const styles = {
  buttonRoot: { color: 'white', borderRadius: 200 },
  buttonLabel: { textTransform: 'capitalize' },
  dialogRoot: { paddingTop: 96 },
  paper: {
    minHeight: 140,
    width: 480
  },
  scrollPaper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start'
  }
}

export default withStyles(styles)(CreateMenu)
