import React, { useState } from 'react'
import styled from 'styled-components'
import { array, func } from 'prop-types'
import { contains, removeValue } from 'utils'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { MdClose, MdDragHandle } from 'react-icons/md'
import Space from 'shared/components/Space'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import AddCategoryDialog from 'pages/menu/components/categories/AddCategoryDialog'

/**---------- styles and styled components ----------*/
const styles = { buttonCritical: { color: 'var(--color-utility-critical)' } }

const Root = styled.div.withConfig({ displayName: 'CategoryDnd' })`
  min-height: 350px;
`

/**---------- util fns ----------*/
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const onDragEnd = (unsavedOrder, updateOrder, { destination, source }) => {
  if (!destination) return null

  updateOrder(reorder(unsavedOrder, source.index, destination.index))
}

/**---------- complementary components ----------*/
const CategoryOptionRaw = ({
  category: { categoryId, displayName },
  index,
  classes,
  remove
}) => (
  <Draggable draggableId={categoryId} index={index}>
    {(provided, snapshot) => {
      return (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <div style={{ display: 'flex', alignItems: 'center', maxWidth: 420 }}>
            <Typography variant="body2">{displayName}</Typography>
            <div
              style={{
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                justifyContent: 'flex-end',
                marginRight: 16
              }}
            >
              <IconButton
                color="primary"
                classes={{
                  colorPrimary: classes.buttonCritical
                }}
                onClick={() => remove(categoryId)}
              >
                <MdClose />
              </IconButton>

              <Space direction="x" value="one" />
              <div {...provided.dragHandleProps}>
                <MdDragHandle />
              </div>
            </div>
          </div>
        </div>
      )
    }}
  </Draggable>
)

const CategoryOption = withStyles(styles)(CategoryOptionRaw)
const getCategoryId = e => e.categoryId

/**---------- main component ----------*/
const CategoryDnd = ({ allOptions, entityType, updateOrder, unsavedOrder }) => {
  const [addingEntities, setAddingEntities] = useState(false)
  const toggleAddingEntities = () => setAddingEntities(!addingEntities)

  const verifiedIds = unsavedOrder.filter(id =>
    contains(allOptions, id, getCategoryId)
  )

  const draggables = verifiedIds.map((id, idx) => {
    const entity = allOptions.find(o => getCategoryId(o) === id)

    // line below _should_ be unreachable, handling just in case
    if (!entity) throw new Error(`error finding category ${id} in children`)

    return (
      <CategoryOption
        key={id}
        category={entity}
        index={idx}
        remove={toBeRemoved =>
          updateOrder(removeValue(verifiedIds, toBeRemoved))
        }
      />
    )
  })

  return (
    <Root>
      <DragDropContext
        onDragEnd={result => onDragEnd(verifiedIds, updateOrder, result)}
      >
        <AddCategoryDialog
          entityType={entityType}
          options={allOptions.filter(
            o => !contains(verifiedIds, getCategoryId(o))
          )}
          open={addingEntities}
          submit={ids => updateOrder([...verifiedIds, ...ids])}
          toggleOpen={toggleAddingEntities}
        />

        <Droppable droppableId="readbackCategories">
          {(provided, snapshot) => {
            return (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {draggables}
                {provided.placeholder}
              </div>
            )
          }}
        </Droppable>
      </DragDropContext>
    </Root>
  )
}

CategoryDnd.propTypes = {
  allOptions: array.isRequired,
  currentOrder: array,
  unsavedOrder: array.isRequired,
  updateOrder: func.isRequired
}

export default withStyles(styles)(CategoryDnd)
