import React, { useState } from 'react'
import { string, func } from 'prop-types'
import voca from 'voca'
import get from 'lodash.get'
import isequal from 'lodash.isequal'
import cloneDeep from 'lodash.clonedeep'
import { mutateCategoryComponentForFormik } from 'shared/graphql/utils'
import {
  contains,
  ifNil,
  ifEmpty,
  isEmpty,
  combineMessages,
  compareUsingKey,
  compareNums,
  uniqueOn,
  isNil,
  notNil,
  allTrue,
  asyncForEach,
  selectKeys,
  update
} from 'utils'
import styled from 'styled-components'
import * as compose from 'lodash.flowright'
import { Formik, FieldArray } from 'formik'
import { useConceptLocations } from 'shared/hooks'
import * as yup from 'yup'
import Checkbox from '@material-ui/core/Checkbox'
import MenuItem from '@material-ui/core/MenuItem'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import ModifierGroupsManager from 'pages/menu/components/review/ModifierGroupsManager'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContentText from '@material-ui/core/DialogContentText'
import { withStyles } from '@material-ui/core/styles'
import { Space, Container } from 'shared/components'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import AddTagDialog from 'pages/menu/components/review/AddTagDialog'
import Chip from '@material-ui/core/Chip'
import Button from '@material-ui/core/Button'
import { useQuery, useMutation } from 'react-apollo'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import SetPromptingOrder from 'pages/menu/components/review/SetPromptingOrder'
import InputAdornment from '@material-ui/core/InputAdornment'
import Typography from '@material-ui/core/Typography'
import CreateCategoryComponent from 'pages/menu/components/review/CreateCategoryComponent'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import FileCopy from '@material-ui/icons/FileCopyOutlined'
import ParentCategory from '@material-ui/icons/SupervisorAccount'
import GET_ELEMENT from 'pages/menu/graphql/queries/getElement'
import GET_CATEGORIES from 'pages/menu/graphql/queries/getCategories'
import { breakPoints } from 'shared/breakPoints'
import {
  CREATE_CATEGORY_COMPONENT,
  DESTROY_CATEGORY_COMPONENT,
  CREATE_ELEMENT_COMPONENT,
  DESTROY_ELEMENT_COMPONENT
} from 'pages/menu/graphql/mutations/components'
import UPDATE_CATEGORY_COMPONENT from 'pages/menu/graphql/mutations/updateCategoryComponent'
import UPDATE_ELEMENT_COMPONENT from 'pages/menu/graphql/mutations/updateElementComponent'
import UPDATE_ELEMENT from 'pages/menu/graphql/mutations/updateElement'

/*---------- styles and styled components ----------*/
const styles = {
  formControlLabelRoot: {
    marginRight: 4
  },
  textFieldRoot: {
    marginBottom: 16
  },
  textFieldExtraBottomMargin: {
    marginBottom: 20
  }
}

const Root = styled.div.withConfig({ displayName: 'EditElementRoot' })`
  align-items: ${props => (props.centerAll ? 'center' : 'initial')};
  display: ${props => (props.centerAll ? 'flex' : 'initial')};
  justify-content: ${props => (props.centerAll ? 'center' : 'initial')};
  height: 100%;
  padding-bottom: 24px;
`

const TagsBox = styled.div.withConfig({ displayName: 'TagsBox' })`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${props => props.justifyContent || "space-evenly"};
  margin-top: 8px;
  padding: 8px;
  width: 100%;
`

const StickyContainer = styled.div.withConfig({
  displayName: 'StickyContainer'
})`
  background-color: white;
  position: fixed;
  top: 64px;
  width: 100%;
  z-index: 1;

  @media ${breakPoints.muiLarge} {
    width: calc(100% - 265px);
  }
`

/*---------- util fns ----------*/
const makeInitialValues = e => {
  const element = { ...e }

  const formikModifierGroups = Array.from(element.modifierGroups)

  formikModifierGroups.forEach(g => mutateCategoryComponentForFormik(g))

  formikModifierGroups.sort((a, b) =>
    compareUsingKey(a, b, 'category.displayName')
  )

  const promptingOrder = uniqueOn(
    Array.from(element.modifierGroups),
    'category.categoryId'
  )
    .sort((a, b) => compareNums(a.priority, b.priority))
    .map(g => g.category.categoryId)

  const allSelections = []

  element.modifierGroups.forEach(g =>
    g.selections.forEach(s => {
      if (
        !contains(
          allSelections,
          s.element.elementId,
          sel => sel.element.elementId
        )
      )
        allSelections.push(s)
    })
  )

  const antiElements = {}

  allSelections.forEach(s => {
    s.antiElements.forEach(id => {
      antiElements[s.element.elementId]
        ? (antiElements[s.element.elementId] = [
            ...antiElements[s.element.elementId],
            id
          ])
        : (antiElements[s.element.elementId] = [id])
    })
  })

  return {
    categoryId: get(element, 'category.categoryId'),
    abbreviation: ifNil(get(element, 'abbreviation'), ''),
    categoryReadbackName: ifNil(get(element, 'categoryReadbackName'), ''),
    comboOnly: element.comboOnly,
    description: ifNil(get(element, 'description'), ''),
    displayName: ifNil(get(element, 'displayName'), ''),
    displayPlural: ifNil(get(element, 'displayPlural'), ''),
    elementType: element.elementType,
    lookupName: ifNil(get(element, 'lookupName'), ''),
    modifierGroups: formikModifierGroups,
    promptingOrder,
    antiElements,
    parts: element.parts,
    price: ifNil(get(element, 'price'), 0),
    posId: ifNil(get(element, 'posId'), ''),
    speechHints: ifNil(get(element, 'speechHints'), []),
    tags: ifNil(get(element, 'tags'), [])
  }
}

const makeValidationSchema = () => {
  return yup.lazy(values => {
    return yup.object().shape({
      description: yup.string(),
      displayName: yup.string().required('a display name is required'),
      displayPlural: yup.string(),
      lookupName: yup.string(),
      modifierGroups: yup.array().of(
        yup.object().shape({
          menus: yup
            .array()
            .min(1, 'modifier group should be assigned to at least one menu'),
          minQty: yup
            .number()
            .integer('min quantity should not have a decimal')
            .max(999, 'min quantity should be less than 1000')
            .min(0, 'min quantity should be at least 0')
            .required('min quantity is required, enter 0 for "optional"'),
          minSelectionCount: yup
            .number()
            .integer('min selection count should not have a decimal')
            .max(999, 'min selection count should be less than 1000')
        })
      ),
      parts: yup
        .number()
        .integer('parts should not have a decimal')
        .required('parts is required')
        .positive('parts should be greater than 0'),
      price: yup
        .number()
        .integer('price should not have a decimal')
        .max(999999)
        .required('price is required')
    })
  })
}

const buildElementComponentVariables = ({
  componentId,
  defaultValue,
  antiElements
}) => ({
  variables: {
    component: {
      componentId,
      defaultValue,
      antiElements
    }
  }
})

// don't submit empty strings for maxQty or priority
const cleanCategoryComponentVariables = obj => {
  update(obj, 'maxQty', val => ifEmpty(val, null))
  update(obj, 'minSelectionCount', val => ifEmpty(val, null))
  update(obj, 'maxSelectionCount', val => ifEmpty(val, null))
  update(obj, 'priority', val => ifEmpty(val, 1))
  return obj
}

const buildNewElementComponentVariables = s => ({
  defaultValue: ifNil(get(s, 'defaultValue'), false),
  elementId: s.element.elementId,
  parent: s.parent,
  antiElements: s.antiElements
})

const buildCategoryComponentVariables = c => {
  const basicFields = cleanCategoryComponentVariables(
    selectKeys(c, [
      'componentId',
      'addDefault',
      'minSelectionCount',
      'maxSelectionCount',
      'minQty',
      'maxQty',
      'priority',
      'partitionable'
    ])
  )

  return {
    variables: {
      component: {
        ...basicFields,
        menus: c.menus.map(m => m.id),
        proseHint: update(
          selectKeys(get(c, 'proseHint'), [
            'isOptionalUpsell',
            'muteAll',
            'muteReadback',
            'useUpToMax',
            'readbackOrder',
            'readbackQuantity',
            'helpStatement'
          ]),
          'readbackQuantity',
          val => ifEmpty(val, null)
        )
      }
    }
  }
}

const handleSubmit = async (
  values,
  {
    alertResult,
    element,
    createElementComponent,
    destroyElementComponent,
    createCategoryComponent,
    destroyCategoryComponent,
    setSubmitting,
    updateElement,
    updateCategoryComponent,
    updateElementComponent
  }
) => {
  const { elementId } = element

  // recreate initial values
  const initial = makeInitialValues(element)

  // independent component operations
  const newCategoryComponents = []
  const newElementComponents = []
  const destroyElementComponents = []
  const destroyCategoryComponents = []
  const categoryComponentUpdates = []
  const elementComponentUpdates = []

  // populate basicUpdate
  const basicUpdates = async () => {
    const basicKeys = [
      'abbreviation',
      'categoryReadbackName',
      'categoryId',
      'comboOnly',
      'displayName',
      'displayPlural',
      'elementType',
      'lookupName',
      'speechHints',
      'parts',
      'price',
      'tags'
    ]
    if (
      !isequal(selectKeys(initial, basicKeys), selectKeys(values, basicKeys))
    ) {
      return updateElement({
        variables: { element: { ...selectKeys(values, basicKeys), elementId } }
      })
    } else
      return Promise.resolve({
        data: { success: 'true. no basic info was changed' }
      })
  }

  // populate destroyCategoryComponents array
  initial.modifierGroups.forEach(g => {
    if (!contains(values.modifierGroups, g.componentId, c => c.componentId))
      destroyCategoryComponents.push({
        componentId: g.componentId
      })
  })

  // populate categoryComponentUpdates/newElementComponents, case: exisiting modifier groups
  values.modifierGroups.forEach((group, idx) => {
    // clone group for purposes of mutation
    const g = { ...group }

    const ccKeys = [
      'addDefault',
      'minSelectionCount',
      'maxSelectionCount',
      'minQty',
      'menus',
      'maxQty',
      'proseHint',
      'priority',
      'partitionable'
    ]

    const originalCC = initial.modifierGroups.find(
      o => o.componentId === g.componentId
    )

    if (notNil(originalCC)) {
      // update the priority before comparing to original value
      g.priority = values.promptingOrder.indexOf(g.category.categoryId) + 1

      if (!isequal(selectKeys(originalCC, ccKeys), selectKeys(g, ccKeys))) {
        categoryComponentUpdates.push(g)
      }

      // check for destroyed element components
      originalCC.selections.forEach(s => {
        if (!contains(g.selections, s.componentId, val => val.componentId))
          destroyElementComponents.push({
            id: s.componentId,
            parent: g.componentId
          })
      })

      g.selections.forEach(s => {
        const ecKeys = ['defaultValue', 'antiElements']

        const originalEC = originalCC.selections.find(
          c => c.componentId === s.componentId
        )

        // check for EC updates
        if (notNil(originalEC)) {
          if (
            !isequal(selectKeys(originalEC, ecKeys), {
              ...selectKeys(s, ecKeys),
              antiElements: values.antiElements[s.element.elementId] || []
            })
          ) {
            elementComponentUpdates.push({
              ...s,
              antiElements: values.antiElements[s.element.elementId]
            })
          }
        }

        // check for new EC
        else if (isNil(originalEC)) {
          newElementComponents.push(
            buildNewElementComponentVariables({
              ...s,
              antiElements: values.antiElements[s.element.elementId],
              parent: originalCC.componentId
            })
          )
        }
      })
    }
  })

  // populate newCategoryComponents/newElementComponents array, case: new modifier groups
  values.modifierGroups.forEach((group, idx) => {
    const g = { ...group }

    const ccInputKeys = [
      'addDefault',
      'categoryId',
      'minSelectionCount',
      'maxSelectionCount',
      'menus',
      'minQty',
      'maxQty',
      'priority',
      'proseHint',
      'selections'
    ]

    const comp = cleanCategoryComponentVariables(selectKeys(g, ccInputKeys))

    // if modifier group doesn't currently exist, create it and selections
    if (!contains(initial.modifierGroups, g.componentId, c => c.componentId)) {
      comp.ownerId = element.elementId
      comp.priority = values.promptingOrder.indexOf(comp.categoryId) + 1
      comp.menus = comp.menus.map(m => m.id)
      update(comp, 'selections', selections => {
        return selections.map(s => {
          return {
            defaultValue: s.defaultValue,
            elementId: s.element.elementId,
            antiElements: values.antiElements[s.element.elementId] || []
          }
        })
      })
      update(comp, 'proseHint', hint =>
        update(
          selectKeys(hint, [
            'isOptionalUpsell',
            'muteAll',
            'muteReadback',
            'readbackOrder',
            'readbackQuantity',
            'useUpToMax',
            'helpStatement'
          ]),
          'readbackQuantity',
          val => ifEmpty(val, null)
        )
      )
      newCategoryComponents.push(comp)
    }
  })

  try {
    const basicUpdateResults = await basicUpdates()

    const updateCategoryComponentResults = await asyncForEach(
      categoryComponentUpdates,
      c => updateCategoryComponent(buildCategoryComponentVariables(c))
    )

    const newCategoryComponentsResults = await asyncForEach(
      newCategoryComponents,
      component => createCategoryComponent({ variables: { component } })
    )

    const newElementComponentsResults = await asyncForEach(
      newElementComponents,
      component => createElementComponent({ variables: { component } })
    )

    const destroyCategoryComponentResults = await asyncForEach(
      destroyCategoryComponents,
      variables => destroyCategoryComponent({ variables })
    )

    const destroyElementComponentsResults = await asyncForEach(
      destroyElementComponents,
      variables => destroyElementComponent({ variables })
    )

    const updateElementComponentResults = await asyncForEach(
      elementComponentUpdates,
      c => updateElementComponent(buildElementComponentVariables(c))
    )

    console.table({
      'basic updates': { success: basicUpdateResults.data.success }
    })
    console.table({
      'create element components': newElementComponentsResults.map(
        ({ data }) => data.success
      ),
      'destroy element components': destroyElementComponentsResults.map(
        ({ data }) => data.success
      ),
      'create category components': newCategoryComponentsResults.map(
        ({ data }) => data.success
      ),
      'destroy category components': destroyCategoryComponentResults.map(
        ({ data }) => data.success
      ),
      'update category components': updateCategoryComponentResults.map(
        ({ data: result }) => result.success
      ),
      'upate element components': updateElementComponentResults.map(
        ({ data: result }) => result.success
      )
    })
    alertResult(`${element.displayName} was successfully updated!`)
    setSubmitting(false)
  } catch (err) {
    console.error(err)
    alertResult(`error: ${err}`)
    setSubmitting(false)
  }
}

/*---------- complementary components ----------*/
const ErrorMessage = ({ message }) => (
  <p
    style={{
      color: 'crimson',
      fontSize: '.75rem',
      marginTop: 0,
      marginBottom: 16
    }}
  >
    {message}
  </p>
)

/*---------- main component ----------*/
const EditElement = ({ classes, back, elementId }) => {
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertText, setAlertText] = useState('')
  const { filter } = useConceptLocations()

  const { data, error, loading } = useQuery(GET_ELEMENT, {
    variables: { elementId }
  })

  const {
    data: categoriesData,
    error: categoriesError,
    loading: categoriesLoading
  } = useQuery(GET_CATEGORIES, {
    variables: {
      conceptId: get(filter, 'conceptId')
    },
    skip: get(filter, 'conceptId') === null
  })

  const [updateElement] = useMutation(UPDATE_ELEMENT, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_CATEGORIES,
        variables: { conceptId: get(filter, 'conceptId') }
      }
    ]
  })

  const [createCategoryComponent] = useMutation(CREATE_CATEGORY_COMPONENT)
  const [destroyCategoryComponent] = useMutation(DESTROY_CATEGORY_COMPONENT)
  const [createElementComponent] = useMutation(CREATE_ELEMENT_COMPONENT)
  const [destroyElementComponent] = useMutation(DESTROY_ELEMENT_COMPONENT)
  const [updateCategoryComponent] = useMutation(UPDATE_CATEGORY_COMPONENT)
  const [updateElementComponent] = useMutation(UPDATE_ELEMENT_COMPONENT)

  const alertResult = message => {
    setAlertText(message)
    setAlertOpen(true)
  }

  /*--------------------- handle loading and error states here ---------------------*/

  if (error || categoriesError)
    throw new Error(combineMessages([error, categoriesError]))

  if (loading || categoriesLoading || !data || !categoriesData)
    return <Root children={<CircularProgress />} centerAll />

  // do not pass these values
  const { element: _e } = data
  const { categories } = categoriesData

  const element = Object.freeze(cloneDeep(_e))

  /*--------------------- to be rendered when graphql query succeeds ---------------------*/

  return (
    <Formik
      displayName="EditElement"
      initialValues={makeInitialValues(element)}
      validate={values => {
        let errors = { modifierGroups: {} }

        values.modifierGroups.forEach((g, idx) => {
          if (
            g.addDefault === true &&
            isNil(g.selections.find(s => s.defaultValue === true))
          ) {
            errors.modifierGroups[idx] = {
              addDefault:
                'please add default selections for category or turn off "automatically add default selections"'
            }
          }
        })

        if (isEmpty(errors.modifierGroups)) delete errors.modifierGroups

        return errors
      }}
      validationSchema={makeValidationSchema()}
      onSubmit={async (values, { setSubmitting }) => {
        await handleSubmit(values, {
          setSubmitting,
          alertResult,
          createElementComponent,
          destroyElementComponent,
          element,
          updateElement,
          createCategoryComponent,
          destroyCategoryComponent,
          updateCategoryComponent,
          updateElementComponent
        })
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setTouched,
        isSubmitting,
        setFieldValue,
        validateForm
      }) => {
        const currentModifierGroups = {}

        // key currentModifierGroups by menu ID
        values.modifierGroups.forEach(g =>
          g.menus.forEach(
            m =>
              (currentModifierGroups[m.id] = currentModifierGroups[m.id]
                ? [...currentModifierGroups[m.id], g]
                : [g])
          )
        )

        return (
          <Root>
            <Dialog open={alertOpen}>
              <Container display="flex" justifyContent="space-between">
                <DialogTitle style={{ textAlign: 'center' }}>
                  Category Update Results
                </DialogTitle>
                <Container width="64px" />
              </Container>

              <Space value="one" />

              <DialogContent>
                <DialogContentText>{alertText}</DialogContentText>
              </DialogContent>

              <DialogActions>
                <Button
                  onClick={() => {
                    setAlertOpen(false)
                    back()
                  }}
                  color="primary"
                  autoFocus
                >
                  Ok
                </Button>
              </DialogActions>
            </Dialog>

            <StickyContainer>
              <Space value="one" />

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                  disableRipple
                  onClick={back}
                  style={{ textTransform: 'capitalize' }}
                >
                  <Typography
                    style={{ color: 'var(--novo-light-gray)' }}
                    variant="subtitle1"
                  >
                    Cancel
                  </Typography>
                </Button>

                <Button
                  color="primary"
                  disableRipple
                  disabled={isSubmitting || Object.keys(errors).length !== 0}
                  onClick={handleSubmit}
                  style={{ textTransform: 'capitalize' }}
                >
                  <Typography color="inherit" variant="subtitle1">
                    Save
                  </Typography>
                </Button>
              </div>

              <Typography variant="h6" style={{ textAlign: 'center' }}>
                <em>Editing {element.displayName}</em>
              </Typography>
              <Typography variant="h6" style={{ textAlign: 'center' }}>
                <em style={{ fontSize: '1rem', marginRight: 5 }}>
                  ID {element.elementId}
                </em>
                <IconButton
                  onClick={() =>
                    navigator.clipboard.writeText(element.elementId)
                  }
                  style={{
                    padding: 8
                  }}
                >
                  <Tooltip title="Copy Element ID">
                    <FileCopy
                      size=".75em"
                      style={{
                        color: 'var(--novo-dark-gray)',
                        fontSize: '1.2rem'
                      }}
                    />
                  </Tooltip>
                </IconButton>
              </Typography>
            </StickyContainer>
            <Space value="four" />

            <Space value="two" />
            <Space value="two" />

            <form style={{ padding: '0px 16px' }}>
              <Space value="one" />

              <TextField
                classes={{
                  root: classes.textFieldRoot
                }}
                fullWidth
                label="Display Name"
                type="text"
                name="displayName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.displayName}
              />
              {allTrue(obj => notNil(get(obj, 'displayName')), [
                errors,
                touched
              ]) && <ErrorMessage message={get(errors, 'displayName')} />}
              <TextField
                classes={{
                  root: classes.textFieldRoot
                }}
                fullWidth
                label="Display Name Plural"
                type="text"
                name="displayPlural"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.displayPlural}
              />

              <TextField
                fullWidth
                classes={{
                  root: classes.textFieldRoot
                }}
                label="Lookup Name"
                type="text"
                name="lookupName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.lookupName}
              />

              {errors.lookupName && touched.lookupName && (
                <p
                  style={{
                    color: 'red',
                    fontSize: 12,
                    textAlign: 'right'
                  }}
                >
                  {errors.lookupName}
                </p>
              )}

              <FieldArray
                name="speechHints"
                render={arrayHelpers => {
                  const hints = values.speechHints.map((h, index) => (
                    <Chip
                      name={`{speechHints.${index}`}
                      key={index}
                      label={h}
                      onDelete={() => arrayHelpers.remove(index)}
                      style={{
                        margin: 4
                      }}
                    />
                  ))
                  return (
                    <>
                      <FormGroup>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <FormLabel>Custom Speech Hints</FormLabel>

                          <AddTagDialog
                            currentTags={values.speechHints}
                            title="Create New Speech Hint"
                            addTag={arrayHelpers.push}
                          />
                        </div>
                      </FormGroup>
                      <TagsBox justifyContent="flex-start">{hints}</TagsBox>
                    </>
                  )
                }}
              />

              <TextField
                fullWidth
                classes={{
                  root: classes.textFieldRoot
                }}
                label="Abbreviation"
                inputProps={{ maxLength: 10 }}
                type="text"
                name="abbreviation"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.abbreviation}
              />

              <TextField
                classes={{
                  root: classes.textFieldRoot
                }}
                fullWidth
                label="Parts"
                type="number"
                name="parts"
                InputProps={{
                  step: '1'
                }}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.parts}
              />

              {allTrue(obj => !isNil(get(obj, 'parts')), [errors, touched]) && (
                <ErrorMessage message={get(errors, 'parts')} />
              )}

              <TextField
                classes={{
                  root: classes.textFieldRoot
                }}
                fullWidth
                label="Price"
                type="number"
                name="price"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  step: '0.01'
                }}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.price}
              />

              {allTrue(obj => !isNil(get(obj, 'price')), [errors, touched]) && (
                <ErrorMessage message={get(errors, 'price')} />
              )}

              <TextField
                classes={{
                  root: classes.textFieldRoot
                }}
                fullWidth
                select
                label="Element Type"
                onChange={handleChange}
                name="elementType"
                SelectProps={{
                  native: true
                }}
                defaultValue={values.elementType}
              >
                <option>MENU_ITEM</option>
                <option>MODIFIER</option>
                <option>INGREDIENT</option>
                <option>COMBO</option>
                <option>ORGANIZATIONAL</option>
              </TextField>

              <TextField
                classes={{
                  root: classes.textFieldRoot
                }}
                fullWidth
                select
                label="Menu Category"
                onChange={handleChange}
                name="categoryId"
                defaultValue={values.categoryId}
              >
                {categories
                  .sort((a, b) => compareUsingKey(a, b, 'displayName'))
                  .map(c => (
                    <MenuItem key={c.categoryId} value={c.categoryId}>
                      {voca.titleCase(c.displayName)}&ensp;
                      <span style={{ color: 'var(--novo-light-gray)' }}>
                        #{c.categoryId}
                      </span>
                      &ensp;-&ensp;{c.elementType}(s)&emsp;
                      {get(c, 'parent.displayName') && (
                        <Chip
                          icon={<ParentCategory />}
                          label={get(c, 'parent.displayName')}
                          style={{ backgroundColor: 'dark-gray' }}
                        />
                      )}
                    </MenuItem>
                  ))}
              </TextField>

              <TextField
                fullWidth
                classes={{
                  root: classes.textFieldRoot
                }}
                label="Category Readback Name"
                inputProps={{ maxLength: 50 }}
                type="text"
                name="categoryReadbackName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.categoryReadbackName}
              />

              <Space value="one" />

              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    name="comboOnly"
                    checked={values.comboOnly}
                    onChange={({ target: { checked } }) =>
                      setFieldValue('comboOnly', checked)
                    }
                  />
                }
                label="Combo Only"
              />

              <Space value="one" />

              <FormGroup>
                <Container display="flex" alignItems="center">
                  <FormLabel>Modifier Groups</FormLabel>
                  <Space value="half" direction="x" />
                  <CreateCategoryComponent
                    owner={element}
                    currentModifierGroups={currentModifierGroups}
                    submit={c => {
                      const newGroups = Array.from(values.modifierGroups)

                      newGroups.push(c)

                      const newPromptingOrder = uniqueOn(
                        newGroups,
                        'category.categoryId'
                      )
                        .sort((a, b) => compareNums(a.priority, b.priority))
                        .map(g => g.category.categoryId)

                      setFieldValue('modifierGroups', newGroups)
                      setFieldValue('promptingOrder', newPromptingOrder)
                    }}
                  />
                  <Space value="half" direction="x" />
                  <SetPromptingOrder
                    element={element}
                    modifierGroups={uniqueOn(
                      Array.from(values.modifierGroups),
                      'category.categoryId'
                    )}
                    promptingOrder={Array.from(values.promptingOrder)}
                  />
                </Container>

                <Space value="half" />

                <div
                  style={{
                    marginBottom: 10,
                    minHeight: 105,
                    paddingLeft: 4
                  }}
                >
                  <ModifierGroupsManager
                    element={element}
                    menuToModifierGroups={currentModifierGroups}
                    modifierGroups={Array.from(values.modifierGroups)}
                  />
                </div>
              </FormGroup>

              <Space value="one" />

              <FieldArray
                name="tags"
                render={arrayHelpers => {
                  const elementTags = values.tags.map((t, index) => (
                    <Chip
                      name={`{tags.${index}`}
                      key={index}
                      label={t}
                      onDelete={() => arrayHelpers.remove(index)}
                      style={{
                        margin: 4
                      }}
                    />
                  ))
                  return (
                    <>
                      <FormGroup>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <FormLabel>Tags</FormLabel>

                          <AddTagDialog
                            currentTags={values.tags}
                            addTag={arrayHelpers.push}
                          />
                        </div>
                      </FormGroup>
                      <TagsBox>{elementTags}</TagsBox>
                    </>
                  )
                }}
              />
            </form>
          </Root>
        )
      }}
    </Formik>
  )
}

EditElement.propTypes = {
  elementId: string.isRequired,
  back: func.isRequired
}

export default compose(withStyles(styles))(EditElement)
