import React, { useState } from 'react'
import { array } from 'prop-types'
import Autosuggest from 'react-autosuggest'
import Fuse from 'fuse.js'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import { MdClear } from 'react-icons/md'
import Paper from '@material-ui/core/Paper'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { withStyles } from '@material-ui/core/styles'

/**---------- styles and styled components ----------*/
const styles = theme => {
  return {
    container: {
      position: 'relative'
    },
    paperRoot: {
      overflowY: 'scroll',
      maxHeight: 230
    },
    suggestionsContainerOpen: {
      position: 'absolute',
      zIndex: 1,
      marginTop: theme.spacing(),
      left: 0,
      right: 0
    },
    suggestion: {
      display: 'block'
    },
    suggestionsList: {
      margin: 0,
      padding: 0,
      listStyleType: 'none'
    }
  }
}

/**---------- util fns ----------*/
const fuzzyFind = (val, coll, config) => {
  const inputValue = val.trim().toLowerCase()
  const fuse = new Fuse(coll, config)

  if (inputValue.length === 0) return []

  return fuse.search(inputValue)
}

const getDisplayName = suggestion => {
  return suggestion.displayName
}

/**---------- main component ----------*/
const ElementAutosuggest = ({
  allOptions,
  handleClick,
  classes,
  onClickEffect
}) => {
  const [value, setValue] = useState('')
  const [suggestions, setSuggestions] = useState([])
  const fuzzyConfig = {
    shouldSort: true,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: [
      'lookupName',
      'displayName',
      'elementId',
      'category.displayName',
      'category.lookupName'
    ],
    threshold: 0.2
  }
  const renderInput = inputProps => {
    const { classes, ref, value, ...rest } = inputProps

    return (
      <div style={{ display: 'flex' }}>
        <TextField
          fullWidth
          label="Search"
          InputProps={{
            inputRef: ref,
            classes: {
              input: classes.input
            },
            value,
            ...rest
          }}
        />
        <IconButton disabled={value.length === 0} onClick={() => setValue('')}>
          <MdClear />
        </IconButton>
      </div>
    )
  }

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(fuzzyFind(value, allOptions, fuzzyConfig))
  }

  const renderSuggestion = (suggestion, { query, isHighlighted }) => {
    const { category, displayName, elementId } = suggestion

    return (
      <ListItem
        button
        onClick={() => {
          if (onClickEffect) onClickEffect(suggestion)
          handleClick(elementId)
        }}
      >
        <ListItemText
          primary={displayName}
          secondary={`${category.displayName} ${
            category.lookupName === null ? '' : `(${category.lookupName})`
          }`}
        />
      </ListItem>
    )
  }

  const onSuggestionsClearRequested = () => {
    setSuggestions([])
  }

  const renderSuggestionsContainer = options => {
    const { containerProps, children } = options

    return (
      <React.Fragment>
        {children && (
          <Paper
            classes={{ root: classes.paperRoot }}
            {...containerProps}
            square
          >
            {children}
          </Paper>
        )}
      </React.Fragment>
    )
  }

  const handleChange = (e, { newValue }) => {
    setValue(newValue)
  }

  return (
    <Autosuggest
      theme={{
        container: classes.container,
        suggestionsContainerOpen: classes.suggestionsContainerOpen,
        suggestionsList: classes.suggestionsList,
        suggestion: classes.suggestion
      }}
      renderInputComponent={renderInput}
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      renderSuggestionsContainer={renderSuggestionsContainer}
      getSuggestionValue={getDisplayName}
      renderSuggestion={renderSuggestion}
      inputProps={{
        classes,
        placeholder: 'element name/category...',
        value,
        onChange: handleChange
      }}
    />
  )
}

ElementAutosuggest.propTypes = {
  allOptions: array.isRequired
}

export default withStyles(styles, { withTheme: true })(ElementAutosuggest)
