import React, { useState } from 'react'
import { contains } from 'utils'
import styled from 'styled-components'
import Space from 'shared/components/Space'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import { MdAdd } from 'react-icons/md'
import { compareUsingKey } from 'utils'
import { array, bool, func } from 'prop-types'

/**---------- styles and styled components ----------*/
const Root = styled.div.withConfig({ displayName: 'AddCategoryDialog' })``

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`

/**---------- main component ----------*/
const AddCategoryDialog = ({ options, toggleOpen, submit, open }) => {
  const [selected, updateSelected] = useState([])

  const toggleSelected = (event, { categoryId }) => {
    const updated = Array.from(selected)
    const {
      target: { checked }
    } = event

    if (checked === true) {
      updated.push(categoryId)
      updateSelected(updated)
    } else if (checked === false) {
      const index = updated.indexOf(categoryId)
      updated.splice(index, 1)
      updateSelected(updated)
    }
  }

  const allOptions = Array.from(options)
    .sort((a, b) => compareUsingKey(a, b, 'displayName'))
    .map(c => (
      <FormControlLabel
        key={c.categoryId}
        control={
          <Checkbox
            color="primary"
            checked={contains(selected, c.categoryId)}
            onChange={event => toggleSelected(event, c)}
          />
        }
        label={c.displayName}
      />
    ))

  return (
    <Root>
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'flex-start',
          width: 420
        }}
      >
        <FormLabel component="label">Category Readback Order</FormLabel>

        <IconButton color="primary" onClick={toggleOpen}>
          <MdAdd />
        </IconButton>
      </div>

      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={open}
        onClose={toggleOpen}
      >
        <DialogTitle>Add Categories To Readback</DialogTitle>

        <DialogContent>
          <div
            style={{
              backgroundColor: 'white',
              position: 'sticky',
              top: 0,
              zIndex: 1
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                onClick={() => {
                  toggleOpen()
                  updateSelected([])
                }}
              >
                cancel
              </Button>

              <Button
                color="primary"
                onClick={() => {
                  submit(selected)
                  toggleOpen()
                  updateSelected([])
                }}
                disabled={selected.length === 0}
              >
                Add
              </Button>
            </div>
          </div>

          <Space direction="y" value="one" />

          <ColumnContainer>{allOptions}</ColumnContainer>
        </DialogContent>
      </Dialog>
    </Root>
  )
}

AddCategoryDialog.propTypes = {
  options: array.isRequired,
  open: bool.isRequired,
  submit: func.isRequired,
  toggleOpen: func.isRequired
}

export default AddCategoryDialog
