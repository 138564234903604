import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { isEmpty } from 'utils'
import DuplicateIcon from '@material-ui/icons/FileCopy'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import FormControl from '@material-ui/core/FormControl'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import { Space } from 'shared/components'
import { contains, titleCase } from 'utils'
import { decode } from 'utils/graphql'
import { connect } from 'formik'

const styles = {
  iconColorPrimary: {
    color: 'var(--novo-dark-gray)'
  }
}

const DuplicateModifierGroup = ({
  classes,
  menus,
  menuToModifierGroups,
  modifierGroup,
  formik: { values, setFieldValue }
}) => {
  const [open, setOpen] = useState(false)
  const [selectedMenus, setSelectedMenus] = useState([])
  const close = () => {
    setOpen(false)
    setSelectedMenus([])
  }
  const submit = menus => {
    const ids = menus.map(m => m.id)

    const clone = { ...modifierGroup }

    clone.categoryId = clone.category.categoryId

    clone.menus = menus

    clone.componentId = `TBC${clone.category.categoryId}-${ids.join('***')}`

    setFieldValue('modifierGroups', [...values.modifierGroups, clone])
  }

  // menus that don't contain this modifier group
  const assignableMenus = menus.filter(m => {
    if (
      menuToModifierGroups[m.id] &&
      menuToModifierGroups[m.id].find(
        g => g.category.categoryId === modifierGroup.category.categoryId
      )
    )
      return false
    else return true
  })

  return (
    <>
      <IconButton
        color="primary"
        onClick={() => setOpen(true)}
        disabled={isEmpty(assignableMenus)}
        classes={{
          colorPrimary: classes.iconColorPrimary
        }}
      >
        <Tooltip title="duplicate modifier group">
          <DuplicateIcon />
        </Tooltip>
      </IconButton>
      <Dialog open={open} onClose={close}>
        <DialogContent>
          <FormControl variant="standard">
            <InputLabel id="menus">
              Clone{' '}
              <span
                style={{
                  fontFamily: 'Pangram Medium'
                }}
              >
                {modifierGroup.category.displayName}
              </span>{' '}
              For Menus
            </InputLabel>
            <Select
              multiple
              labelId="menus"
              name="menus"
              value={selectedMenus}
              renderValue={coll =>
                coll
                  .map(
                    modifierGroupMenu =>
                      menus.find(m => m.id === modifierGroupMenu.id).name
                  )
                  .map(m => titleCase(m))
                  .join(', ')
              }
              onChange={e => {
                setSelectedMenus(
                  e.target.value.filter(
                    m =>
                      e.target.value.filter(menu => menu.id === m.id).length ===
                      1
                  )
                )
              }}
              input={<Input fullWidth />}
              style={{ minWidth: 400 }}
            >
              {assignableMenus.map(m => (
                <MenuItem key={m.id} value={m}>
                  <Checkbox
                    checked={contains(selectedMenus, m.id, mgm => mgm.id)}
                    color="primary"
                  />
                  <ListItemText
                    primary={`${titleCase(m.name)} - #${decode(m.id).id}`}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Space direction="y" value="one" />

          <Space direction="y" value="one" />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={e => {
              submit(selectedMenus)
              close()
            }}
            color="primary"
            disabled={isEmpty(selectedMenus)}
            style={{ textTransform: 'none' }}
          >
            create
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default connect(withStyles(styles)(DuplicateModifierGroup))
