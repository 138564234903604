import gql from 'graphql-tag'

export const GET_MENU = gql`
  query menu($id: ID!) {
    menu(id: $id) {
      id
      default
      name
      concept {
        id
        name
      }
      locations {
        locationId
      }
    }
  }
`

export const GET_MENUS = gql`
  query menus($concept: ID!) {
    menus(concept: $concept) {
      id
      default
      name
      concept {
        id
        name
      }
      locations {
        locationId
      }
    }
  }
`
