import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { Space } from 'shared/components'
import styled from 'styled-components'
import MenuElementsManager from 'pages/menu/components/menu-items/MenuElementsManager'
import MenuManager from 'pages/menu/components/MenuManager'
import CategoryManager from 'pages/menu/components/categories/CategoryManager'
import ErrorBoundary from 'shared/components/ErrorBoundary'

const MenuManagement = () => (
  <Root>
    <ErrorBoundary level="Menu Manager">
      <Space value="four" />

      <Switch>
        <Route
          path="/c/:conceptId/mms/elements"
          render={() => <MenuElementsManager />}
        />

        <Route
          path="/c/:conceptId/mms/categories"
          render={() => <CategoryManager />}
        />

        <Route path="/c/:conceptId/mms/menus" render={props => <MenuManager {...props} />} />
      </Switch>
    </ErrorBoundary>
  </Root>
)

const Root = styled.div.withConfig({
  displayName: 'MenuManagementRoot'
})`
  background-color: white;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  overflow: scroll;
`

export default MenuManagement
