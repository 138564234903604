import gql from 'graphql-tag'

export default gql`
  query getCategories($conceptId: ID!) {
    categories: menuCategories(conceptId: $conceptId) {
      categoryId
      displayName
      displayPlural
      elementType
      lookupName
      parent {
        categoryId
        displayName
      }
    }
  }
`
