import gql from 'graphql-tag'

export const CREATE_MENU = gql`
  mutation createMenu(
    $name: String!
    $concept: ID!
    $default: Boolean!
    $from: ID
  ) {
    createMenu(concept: $concept, name: $name, default: $default, from: $from)
  }
`

export const EDIT_MENU = gql`
  mutation editMenu($menuId: ID!, $default: Boolean, $name: String) {
    editMenu(menuId: $menuId, default: $default, name: $name)
  }
`

export const ASSIGN_MENU = gql`
  mutation assignMenu(
    $menu: ID!
    $all: Boolean
    $locations: [ID]
    $allExcept: [ID]
  ) {
    assignMenu(
      all: $all
      allExcept: $allExcept
      locations: $locations
      menu: $menu
    )
  }
`
