// Material-UI form components for Formik
import React from 'react'
import MUITextField from '@material-ui/core/TextField'
import MUIFormControlLabel from '@material-ui/core/FormControlLabel'
import MUIButton from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import { connect } from 'formik'
import { isEmpty } from 'utils'

export const TextField = ({
  field: { onChange, onBlur, name, value },
  form,
  meta,
  ...props
}) => {
  return (
    <MUITextField onChange={onChange} onBlur={onBlur} name={name} value={value} {...props} />
  )
}

export const CheckboxWithLabel = ({
  field: { onChange, onBlur, name, value },
  form,
  meta,
  label,
  checkboxProps
}) => {
  return (
    <MUIFormControlLabel
      control={
        <Checkbox
          name={name}
          checked={value}
          onChange={onChange}
          {...checkboxProps}
        />
      }
      label={label}
    />
  )
}

const submit = ({ formik: { errors, touched, dirty, isSubmitting }, title, ...props }) => {
  return (
    <MUIButton type="submit" {...props} disabled={!isEmpty(errors) || isSubmitting || !dirty}>
      {title}
    </MUIButton>
  )
}

export const Submit = connect(submit)
