import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import FormControl from '@material-ui/core/FormControl'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import { Space } from 'shared/components'
import { contains } from 'utils'
import { decode } from 'utils/graphql'
import v from 'voca'

const EditModifierGroupMenus = ({
  modifierGroup,
  menus,
  submit,
  menuToModifierGroups
}) => {
  const [open, setOpen] = useState(false)
  const close = () => setOpen(false)

  // menus that don't contain this modifier group
  const assignableMenus = menus.filter(m => {
    if (
      menuToModifierGroups[m.id] &&
      menuToModifierGroups[m.id].find(
        g => g.category.categoryId === modifierGroup.category.categoryId
      )
    )
      return false
    else return true
  })

  return (
    <>
      <Button
        style={{ textTransform: 'capitalize' }}
        onClick={() => setOpen(true)}
      >
        Edit
      </Button>
      <Dialog open={open} onClose={close}>
        <DialogContent>
          <FormControl variant="standard">
            <InputLabel id="menus">
              Assign{' '}
              <span
                style={{
                  fontFamily: 'Pangram Medium'
                }}
              >
                {modifierGroup.category.displayName}
              </span>{' '}
              To Menus
            </InputLabel>
            <Select
              multiple
              labelId="menus"
              name="menus"
              value={modifierGroup.menus}
              renderValue={coll =>
                coll
                  .map(
                    modifierGroupMenu =>
                      menus.find(m => m.id === modifierGroupMenu.id).name
                  )
                  .join(', ')
              }
              onChange={e => {
                submit(e.target.value.filter(m => e.target.value.filter(menu => menu.id === m.id).length === 1))
              }}
              input={<Input fullWidth />}
              style={{ minWidth: 400 }}
            >
              {menus.map(m => (
                <MenuItem
                  key={m.id}
                  value={m}
                  disabled={
                    // for selection to be enabled the menu should be in assignableMenus, and not already be assigned to this modifier
                    // group
                    !contains(assignableMenus, m.id, menu => menu.id) &&
                    !contains(modifierGroup.menus, m.id, mgm => mgm.id)
                  }
                >
                  <Checkbox
                    checked={contains(modifierGroup.menus, m.id, mgm => mgm.id)}
                    color="primary"
                  />
                  <ListItemText
                    primary={`${v.titleCase(m.name)} - #${decode(m.id).id}`}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Space direction="y" value="one" />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default EditModifierGroupMenus
