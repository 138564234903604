import React, { useState } from 'react'
import {
  Form,
  Formik,
  ErrorMessage as FormikErrorMessage,
  Field,
  connect
} from 'formik'
import ErrorMessage from 'pages/menu/components/ErrorMessage'
import { TextField, CheckboxWithLabel } from 'shared/components/Formik'
import MUITextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import { Space } from 'shared/components'
import { encode, decode } from 'utils/graphql'
import { contains, compareUsingKey, isEmpty } from 'utils'
import { useMutation, useQuery } from 'react-apollo'
import IconButton from '@material-ui/core/IconButton'
import ToggleButton from '@material-ui/lab/ToggleButton'
import Clear from '@material-ui/icons/Clear'
import SelectedIcon from '@material-ui/icons/FormatListBulleted'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Tooltip from '@material-ui/core/Tooltip'
import Chip from '@material-ui/core/Chip'
import FormHelperText from '@material-ui/core/FormHelperText'
import isequal from 'lodash.isequal'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormLabel from '@material-ui/core/FormLabel'
import Typography from '@material-ui/core/Typography'
import InputAdornment from '@material-ui/core/InputAdornment'
import EditMenuIcon from '@material-ui/icons/EditOutlined'
import Button from '@material-ui/core/Button'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import * as yup from 'yup'
import v from 'voca'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { FixedSizeList as List } from 'react-window'
import { EDIT_MENU, ASSIGN_MENU } from 'pages/menu/graphql/mutations/menu'
import { GET_MENUS } from 'pages/menu/graphql/queries/menu'
import GET_LOCATIONS from 'shared/graphql/queries/getMenuLocations'
import Fuse from 'fuse.js'

const fuzzyConfig = {
  shouldSort: true,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ['locationId', 'name'],
  threshold: 0.2
}

const fuzzyFind = (val, coll) => {
  const inputValue = val.trim().toLowerCase()
  const fuse = new Fuse(coll, fuzzyConfig)

  if (inputValue.length === 0) return []

  return fuse.search(inputValue)
}

const OptionLabel = ({ location }) => {
  return (
    <ListItem disableGutters>
      <ListItemText primary={v.titleCase(location.name, ["'"])} />
      <Space direction="x" value="half" />
      <Chip label={`#${location.locationId}`} style={{ color: 'white' }} />
    </ListItem>
  )
}

const submit = ({
  formik: { errors, touched, dirty, isSubmitting, values },
  title,
  ...props
}) => {
  return (
    <Button
      type="submit"
      {...props}
      disabled={
        !isEmpty(errors) ||
        isSubmitting ||
        !dirty ||
        (values.mode === 'EXCLUDE' && isEmpty(values.locations))
      }
    >
      {title}
    </Button>
  )
}

const Submit = connect(submit)

const Assign = ({
  menu,
  conceptId,
  formik: { values, setFieldValue, handleChange },
  classes
}) => {
  const { data, error, loading } = useQuery(GET_LOCATIONS, {
    variables: { conceptId }
  })

  const [inputValue, setInputValue] = useState('')
  const [onlyShowSelected, setOnlyShowSelected] = useState(false)
  const toggleOnlyShowSelected = () => setOnlyShowSelected(!onlyShowSelected)

  const assigned = menu.locations.map(l => l.locationId)

  const assignableLocations = data.menuLocations
    .filter(l => !contains(assigned, l.locationId))
    .map(l => {
      return { ...l, locationId: decode(l.locationId).id }
    })
    .sort((a, b) => compareUsingKey(a, b, 'name'))

  const locationSelected = location =>
    contains(values.locations, location.locationId, loc => loc.locationId)

  if (error) {
    return (
      <>
        <Space direction="y" value="half" />
        <Typography>error while loading locations...</Typography>
        <Space direction="y" value="eighth" />
        <Typography style={{ color: 'crimson' }}>{error.message}</Typography>
      </>
    )
  } else if (loading || !data) {
    return null
  }

  const filtered = (() => {
    if (onlyShowSelected)
      return values.locations.filter(l => locationSelected(l))
    else if (inputValue.trim().length === 0) return assignableLocations
    else return fuzzyFind(inputValue, assignableLocations)
  })()

  const toggleSelected = (event, location) => {
    const newLocations = Array.from(values.locations)

    if (event.target.checked) newLocations.push(location)
    else if (!event.target.checked) {
      const index = values.locations.findIndex(
        l => l.locationId === location.locationId
      )
      newLocations.splice(index, 1)
    }

    setFieldValue('locations', newLocations)
  }

  return (
    <>
      <FormLabel>Locations </FormLabel>

      <Space direction="y" value="eighth" />

      <div style={{ display: 'flex', alignItems: 'center', minHeight: 48 }}>
        <FormHelperText
          style={{ minWidth: 220, color: 'var(--novo-dark-gray)' }}
        >
          <span style={{ fontFamily: 'Pangram Medium' }}>
            {values.mode === 'ASSIGN' && values.locations.length}
            {values.mode === 'EXCLUDE' &&
              assignableLocations.length - values.locations.length}
            {values.mode === 'ALL' && (
              <>
                <span style={{ color: 'var(--color-utility-warning)' }}>
                  all {assignableLocations.length}
                </span>
              </>
            )}
          </span>{' '}
          locations to be assigned
        </FormHelperText>

        <Space direction="x" value="eighth" />
      </div>

      <RadioGroup
        aria-label="Filter Type"
        classes={{
          root: classes.radioGroupRoot
        }}
        name="mode"
        value={values.mode}
        onChange={handleChange}
      >
        <FormControlLabel
          value="ASSIGN"
          control={
            <Radio
              classes={{
                checked: classes.radioChecked,
                root: classes.radioRoot
              }}
            />
          }
          label="Only"
        />

        <FormControlLabel
          value="EXCLUDE"
          control={
            <Radio
              classes={{
                checked: classes.radioChecked,
                root: classes.radioRoot
              }}
            />
          }
          label="Exclude"
        />

        <FormControlLabel
          value="ALL"
          control={
            <Radio
              classes={{
                checked: classes.radioChecked,
                root: classes.radioRoot
              }}
            />
          }
          label="All"
        />
      </RadioGroup>

      {values.mode === 'EXCLUDE' && isEmpty(values.locations) && (
        <ErrorMessage message="please select locations to exclude" />
      )}

      {values.mode !== 'ALL' && (
        <>
          <Space direction="y" value="one" />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <MUITextField
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      disabled={inputValue.length === 0}
                      onClick={() => setInputValue('')}
                    >
                      <Tooltip title="clear">
                        <Clear />
                      </Tooltip>
                    </IconButton>
                  </InputAdornment>
                )
              }}
              label="find location"
              placeholder="search locations by name or ID"
              variant="outlined"
              value={inputValue}
              onFocus={() => setOnlyShowSelected(false)}
              onChange={e => setInputValue(e.target.value)}
            />

            <Space direction="x" value="half" />

            <ToggleButton
              selected={onlyShowSelected}
              value="show selected"
              onClick={() => {
                toggleOnlyShowSelected()
                setInputValue('')
              }}
            >
              <Tooltip
                title={(() => {
                  if (onlyShowSelected) return 'show all'
                  else if (onlyShowSelected === false) {
                    if (values.mode === 'ASSIGN') return 'show selected'
                    if (values.mode === 'EXCLUDE') return 'show excluded'
                  }
                })()}
              >
                <SelectedIcon />
              </Tooltip>
            </ToggleButton>
          </div>

          <Space direction="y" value="half" />

          <List
            height={360}
            style={{
              borderRadius: '4px',
              overscrollBehavior: 'contain'
            }}
            itemCount={filtered.length}
            itemSize={64}
          >
            {({ index, style }) => {
              const l = filtered[index]
              return (
                <FormControlLabel
                  key={l.locationId}
                  control={
                    <Checkbox
                      color="primary"
                      checked={contains(
                        values.locations,
                        l.locationId,
                        loc => loc.locationId
                      )}
                      onChange={event => toggleSelected(event, l)}
                    />
                  }
                  label={<OptionLabel location={l} />}
                  style={{ display: 'flex', ...style }}
                />
              )
            }}
          </List>
        </>
      )}
    </>
  )
}

const AssignLocations = connect(Assign)

const EditMenuForm = ({ submit, classes, menu, open, close, conceptId }) => {
  return (
    <Dialog
      open={open}
      onClose={close}
      classes={{
        paper: classes.paper,
        root: classes.dialogRoot,
        scrollPaper: classes.scrollPaper
      }}
      disableBackdropClick
    >
      <DialogTitle style={{ textAlign: 'center' }}>Edit Menu</DialogTitle>

      <DialogContent>
        <Formik
          initialValues={{
            name: menu.name,
            default: menu.default,
            locations: [],
            mode: 'ASSIGN'
          }}
          onSubmit={submit}
          validationSchema={yup.object().shape({
            name: yup.string().required('a menu name is required')
          })}
        >
          <Form>
            <Field
              component={TextField}
              name="name"
              fullWidth
              required
              label="menu name"
            />

            <FormikErrorMessage
              component="p"
              name="name"
              style={{ fontSize: '.75rem', color: 'crimson' }}
            />

            {!menu.default ? (
              <>
                <Space direction="y" value="one" />
                <Field
                  type="checkbox"
                  name="default"
                  label="default menu"
                  component={CheckboxWithLabel}
                  checkboxProps={{ color: 'primary' }}
                />
              </>
            ) : (
              <Space direction="y" value="one" />
            )}

            <Space direction="y" value="one-and-half" />

            <AssignLocations
              classes={classes}
              menu={menu}
              conceptId={conceptId}
            />

            <Space direction="y" value="half" />

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button classes={{ label: classes.buttonLabel }} onClick={close}>
                cancel
              </Button>

              <Space direction="x" value="one" />

              <Submit
                color="primary"
                title="save"
                classes={{ label: classes.buttonLabel }}
              />
            </div>
          </Form>
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

const Status = ({
  classes,
  editMenuStatus,
  assignMenuStatus,
  handleClose,
  open
}) => {
  return (
    <Dialog
      classes={{
        paper: classes.paper,
        root: classes.dialogRoot,
        scrollPaper: classes.scrollPaper
      }}
      disableBackdropClick
      open={open}
      onClose={handleClose}
    >
      <DialogTitle style={{ textAlign: 'center' }}>Edit Menu</DialogTitle>

      <DialogContent>
        {editMenuStatus.error && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Typography>error while editing menu: </Typography>
            <Space direction="y" value="eighth" />
            <Typography style={{ color: 'crimson' }}>
              editMenuStatus.error.message
            </Typography>
          </div>
        )}

        {editMenuStatus.loading && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <CircularProgress size={24} />
            <Space direction="y" value="half" />
            <Typography>updating menu info...</Typography>
          </div>
        )}

        {editMenuStatus.data && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <Typography style={{ color: 'var(--novo-green)' }}>
              menu edited successfully!
            </Typography>
          </div>
        )}

        <Space direction="y" value="one-and-half" />

        {assignMenuStatus.error && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Typography>error while assigning menu: </Typography>
            <Space direction="y" value="eighth" />
            <Typography style={{ color: 'crimson' }}>
              {assignMenuStatus.error.message}
            </Typography>
          </div>
        )}

        {assignMenuStatus.loading && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <CircularProgress size={24} />
            <Space direction="x" value="half" />
            <Typography>assigning menus...</Typography>
          </div>
        )}

        {assignMenuStatus.data && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <Typography style={{ color: 'var(--novo-green)' }}>
              menu assigned to locations successfully!
            </Typography>
          </div>
        )}

        <Space direction="y" value="one" />

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            disabled={editMenuStatus.loading || assignMenuStatus.loading}
            onClick={handleClose}
          >
            ok
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

const EditMenu = ({ classes, menu, activeConceptId }) => {
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const [editStatus, setEditStatus] = useState({})
  const [assignStatus, setAssignStatus] = useState({})
  const [statusDialogOpen, setStatusDialogOpen] = useState(false)
  const reset = () => {
    setEditStatus({})
    setAssignStatus({})
  }

  const [editMenu] = useMutation(EDIT_MENU, {
    refetchQueries: [
      {
        query: GET_MENUS,
        variables: {
          concept: activeConceptId
        }
      }
    ]
  })

  const [assignMenu] = useMutation(ASSIGN_MENU, {
    refetchQueries: [
      {
        query: GET_MENUS,
        variables: {
          concept: activeConceptId
        }
      }
    ]
  })

  const closeEditDialog = () => setEditDialogOpen(false)

  const submit = async values => {
    const assignMenuVariables = (() => {
      if (values.mode === 'ALL') {
        return { all: true }
      } else if (values.mode === 'ASSIGN' && !isEmpty(values.locations))
        return {
          locations: values.locations.map(l => encode(l.locationId, 'Location'))
        }
      else if (values.mode === 'EXCLUDE' && !isEmpty(values.locations))
        return {
          allExcept: values.locations.map(l => encode(l.locationId, 'Location'))
        }
      else return null
    })()

    setStatusDialogOpen(true)

    setEditDialogOpen(false)

    if (assignMenuVariables)
      await assignMenu({
        variables: {
          menu: menu.id,
          ...assignMenuVariables
        }
      })
        .then(data => setAssignStatus(data))
        .catch(err => setAssignStatus(err))

    if (
      !isequal(
        { name: values.name, default: values.default },
        { name: menu.name, default: menu.default }
      )
    )
      await editMenu({
        variables: {
          name: values.name,
          default: values.default,
          menuId: menu.id
        }
      })
        .then(data => setEditStatus(data))
        .catch(err => setEditStatus(err))
  }

  const closeAll = () => {
    reset()
    setEditDialogOpen(false)
    setStatusDialogOpen(false)
  }

  return (
    <>
      <IconButton
        onClick={e => {
          e.stopPropagation()
          setEditDialogOpen(true);
        }}
      >
        <Tooltip title="edit menu">
          <EditMenuIcon />
        </Tooltip>
      </IconButton>

      <EditMenuForm
        open={editDialogOpen}
        menu={menu}
        close={closeEditDialog}
        submit={submit}
        classes={classes}
        conceptId={activeConceptId}
      />

      <Status
        editMenuStatus={editStatus}
        assignMenuStatus={assignStatus}
        handleClose={closeAll}
        classes={classes}
        open={statusDialogOpen}
      />
    </>
  )
}

const styles = {
  buttonRoot: { color: 'white', borderRadius: 200 },
  buttonLabel: { textTransform: 'capitalize' },
  dialogRoot: { paddingTop: 60 },
  paper: {
    minHeight: 140,
    width: 560
  },
  radioChecked: {
    color: [['var(--novo-green)'], '!important']
  },
  radioRoot: {
    backgroundColor: 'white'
  },
  radioGroupRoot: {
    backgroundColor: 'white',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'flex-start',
    position: 'sticky',
    top: 0,
    zIndex: 1
  },
  scrollPaper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start'
  }
}

export default withStyles(styles)(EditMenu)
