import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import get from 'lodash.get'
import { Space } from 'shared/components'
import isequal from 'lodash.isequal'
import Typography from '@material-ui/core/Typography'
import MenuElementsList from 'pages/menu/components/menu-items/MenuElementsList'
import ViewElement from 'pages/menu/components/review/ViewElement'
import EditElement from 'pages/menu/components/review/EditElement'
import ElementAutosuggest from 'pages/menu/components/categories/ElementAutosuggest'
import { breakPoints } from 'shared/breakPoints'
import { useQuery } from 'react-apollo'
import { isNil } from 'utils'
import { useConceptLocations } from 'shared/hooks'
import GET_ELEMENTS from 'pages/menu/graphql/queries/getElements'

/**---------- styles and styled components ----------*/
const Root = styled.div.withConfig({
  displayName: 'MenuElementsManager'
})`
  flex: 1;
`

const StickyContainer = styled.div.withConfig({
  displayName: 'StickyContainer'
})`
  background-color: white;
  flex: 1;
  padding: 16px;
  position: fixed;
  top: ${({ top }) => (top ? top : 'initial')};
  width: 100%;
  z-index: 1;

  @media ${breakPoints.muiLarge} {
    width: calc(100% - 265px);
  }
`

/**---------- complementary components ----------*/
const Title = () => (
  <Typography variant="h6" align="center" style={{ margin: 0 }}>
    Menu Elements
  </Typography>
)

const Loading = () => (
  <Root>
    <StickyContainer top="64px">
      <Title />
    </StickyContainer>
    <p>loading...</p>
  </Root>
)

/**---------- main component ----------*/
const MenuElementsManager = () => {
  const [editing, setEditing] = useState(false)
  const [activeElementId, setActiveElementId] = useState()
  // const [blockScroll, setBlockScroll] = useState(false)
  const { context: conceptLocationsContext, filter } = useConceptLocations()
  const { data, error, loading } = useQuery(GET_ELEMENTS, {
    variables: {
      conceptLocationsFilter: filter
    },
    skip: get(filter, 'conceptId') === null
  })
  const clearActiveElement = () => setActiveElementId(null)

  // references hold their value in between renders, for the life of the component
  const conceptLocationsReference = useRef(filter)

  useEffect(() => {
    if (!isequal(filter, conceptLocationsReference.current)) {
      conceptLocationsReference.current = filter
      clearActiveElement()
      setEditing(false)
    }
  }, [filter])

  if (isNil(get(conceptLocationsContext, 'activeConceptId'))) return <Root />
  if (error) throw new Error(error)
  if (loading || !data) return <Loading />

  const { elements } = data

  return (
    <Root>
      {!activeElementId && (
        <>
          <StickyContainer top="64px">
            <Title />

            <ElementAutosuggest
              allOptions={elements}
              handleClick={id => setActiveElementId(id)}
            />
          </StickyContainer>

          <Space value="four" />
          <Space value="three" />
          <Space value="half" />

          <MenuElementsList
            elements={elements}
            updateActiveElement={setActiveElementId}
          />
        </>
      )}

      {activeElementId && !editing && (
        <ViewElement
          back={clearActiveElement}
          edit={() => setEditing(true)}
          elementId={activeElementId}
        />
      )}

      {activeElementId && editing && (
        <EditElement
          back={() => setEditing(false)}
          elementId={activeElementId}
        />
      )}
    </Root>
  )
}

export default MenuElementsManager
