import gql from 'graphql-tag'

export default gql`
  query element($elementId: ID!) {
    element(elementId: $elementId) {
      abbreviation
      speechHints
      categoryReadbackName
      comboOnly
      parts
      availability {
        available {
          storeAvailable: available
          posId
          locationId
          name
          storeType: type
        }
        unavailable {
          storeAvailable: available
          posId
          locationId
          name
          storeType: type
        }
      }
      category {
        displayName
        categoryId
        parent {
          categoryId
          displayName
        }
      }
      comboElements {
        componentId
        owner {
          displayName
          elementId
        }
      }
      description
      displayName
      displayPlural
      elementId
      elementType
      lookupName
      modifierGroups {
        componentId
        menus { 
          id
        }
        partitionable
        category {
          categoryId
          description
          displayName
          displayPlural
          lookupName
        }
        addDefault
        minQty
        maxQty
        minSelectionCount
        maxSelectionCount
        priority
        proseHint {
          useUpToMax
          helpStatement
          isOptionalUpsell
          muteAll
          muteReadback
          readbackOrder
          readbackQuantity
        }
        selections {
          antiElements
          element {
            displayName
            lookupName
            elementId
          }
          componentId
          defaultValue
        }
      }
      owners {
        componentId
        owner {
          elementId
          displayName
        }
      }
      posId
      price
      tags
    }
  }
`
