import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import PromptingIcon from '@material-ui/icons/SpeakerNotes'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'formik'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'
import { Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd'
import DragHandle from '@material-ui/icons/DragHandle'
import { Space } from 'shared/components'

const portal = document.createElement('div')

if (!document.body) throw new Error('body not ready for portal creation!')

document.body.appendChild(portal)

/**---------- util fns ----------*/
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const onDragEnd = (
  currentFormikOrder,
  updateOrder,
  { destination, source }
) => {
  if (!destination) return null

  updateOrder(reorder(currentFormikOrder, source.index, destination.index))
}

const styles = {}

const DraggableContainer = styled.div.withConfig({ displayName: 'Selection' })`
  background-color: white;
  border-radius: 4px;
  display: flex;
  align-items: center;
  min-height: 56px;
  padding: 12px;
`

const PortalAwareDraggable = ({ provided, snapshot, category }) => {
  const usePortal = snapshot.isDragging

  const child = (
    <DraggableContainer ref={provided.innerRef} {...provided.draggableProps}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}{...provided.dragHandleProps}>
        <DragHandle style={{ height: 24, width: 24 }} />
      </div>
      <Space direction="x" value="half" />
      <Typography>{category.displayName}</Typography>
    </DraggableContainer>
  )

  if (!usePortal) return child

  return ReactDOM.createPortal(child, portal)
}

const CategoryListItemRaw = ({ category, index }) => {
  return (
    <Draggable draggableId={category.categoryId} index={index}>
      {(provided, snapshot) => {
        return (
          <PortalAwareDraggable
            provided={provided}
            snapshot={snapshot}
            category={category}
          />
        )
      }}
    </Draggable>
  )
}

const CategoryListItem = withStyles(styles)(CategoryListItemRaw)

const SetPromptingOrder = ({ modifierGroups, promptingOrder, submit, element, formik: { setFieldValue } }) => {
  const [open, setOpen] = useState(false)
  const close = () => setOpen(false)

  return (
    <>
      <IconButton onClick={() => setOpen(true)}>
        <Tooltip title="set prompting order">
          <PromptingIcon />
        </Tooltip>
      </IconButton>

      <DragDropContext onDragEnd={result => onDragEnd(promptingOrder, newOrder => setFieldValue('promptingOrder', newOrder), result)}>
        <Dialog open={open} onClose={close}>
          <DialogTitle>Prompting Order for {element.displayName}</DialogTitle>
          <DialogContent>
            <Droppable droppableId="prompting-order">
              {(provided, snapshot) => {
                return (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {promptingOrder.map((id, index) => {
                      const group = modifierGroups.find(
                        g => g.category.categoryId === id
                      )

                      return (
                        <CategoryListItem
                          category={group.category}
                          key={group.category.categoryId}
                          index={index}
                        />
                      )
                    })}
                    {provided.placeholder}
                  </div>
                )
              }}
            </Droppable>
          </DialogContent>
        </Dialog>
      </DragDropContext>
    </>
  )
}

export default connect(SetPromptingOrder)
