import React, { useState } from 'react'
import { contains } from 'utils'
import styled from 'styled-components'
import Space from 'shared/components/Space'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import ElementAutosuggest from 'pages/menu/components/categories/ElementAutosuggest'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Button from '@material-ui/core/Button'
import { compareUsingKey } from 'utils'
import { array, bool, func } from 'prop-types'

/**---------- styles and styled components ----------*/
const Root = styled.div.withConfig({ displayName: 'AddUpsellElementsDialog' })``

/**---------- complementary components ----------*/
const OptionLabel = ({ element, last }) => {
  const { category } = element
  return (
    <ListItem
      disableGutters
      id={`el${element.elementId}`}
      last={last.toString()}
    >
      <ListItemText
        primary={element.displayName}
        secondary={`${category.displayName} ${
          category.lookupName === null ? '' : `(${category.lookupName})`
        }`}
      />
    </ListItem>
  )
}

/**---------- main component ----------*/
const AddUpsellElementsDialog = ({ options, toggleOpen, submit, open }) => {
  const [selected, updateSelected] = useState([])
  const toggleSelected = (event, { elementId }) => {
    const updated = Array.from(selected)
    const {
      target: { checked }
    } = event

    if (checked === true) {
      updated.push(elementId)
      updateSelected(updated)
    } else if (checked === false) {
      const index = updated.indexOf(elementId)
      updated.splice(index, 1)
      updateSelected(updated)
    }
  }

  const checkSelected = id => {
    const updated = Array.from(selected)
    if (!contains(updated, id)) {
      updated.push(id)
      updateSelected(updated)
    }
  }

  const allOptions = Array.from(options)
    .sort((a, b) => compareUsingKey(a, b, 'displayName'))
    .map((e, idx) => {
      return (
        <FormControlLabel
          key={e.elementId}
          control={
            <Checkbox
              color="primary"
              checked={contains(selected, e.elementId)}
              onChange={event => toggleSelected(event, e)}
            />
          }
          label={<OptionLabel element={e} last={idx === options.length - 1} />}
          style={{ display: 'flex' }}
        />
      )
    })

  return (
    <Root>
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'flex-start',
          width: 420
        }}
      >
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={open}
          onClose={toggleOpen}
        >
          <DialogTitle style={{ textAlign: 'center' }}>
            Add Upsell Elements
          </DialogTitle>

          <DialogContent
            id="elList"
            style={{
              minHeight: 400,
              minWidth: 440,
              paddingTop: 0
            }}
          >
            <div
              style={{
                backgroundColor: 'white',
                position: 'sticky',
                paddingTop: 8,
                top: 0,
                zIndex: 1
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
              >
                <Button
                  onClick={() => {
                    toggleOpen()
                    updateSelected([])
                  }}
                >
                  cancel
                </Button>

                <Button
                  color="primary"
                  onClick={() => {
                    submit(selected)
                    toggleOpen()
                    updateSelected([])
                  }}
                  disabled={selected.length === 0}
                >
                  Add
                </Button>
              </div>

              <ElementAutosuggest
                allOptions={options}
                onClickEffect={element => {
                  const el = document.getElementById(`el${element.elementId}`)

                  if (el) {
                    const adjust =
                      el.attributes.getNamedItem('last').value === 'false'
                    el.scrollIntoView(true)
                    if (adjust)
                      document.getElementById('elList').scrollTop -= 95
                  }
                }}
                handleClick={checkSelected}
              />

              <Space direction="y" value="one" />
            </div>

            <List>{allOptions}</List>
          </DialogContent>
        </Dialog>
      </div>
    </Root>
  )
}

AddUpsellElementsDialog.propTypes = {
  options: array.isRequired,
  open: bool.isRequired,
  submit: func.isRequired,
  toggleOpen: func.isRequired
}

export default AddUpsellElementsDialog
