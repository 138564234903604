import gql from 'graphql-tag'

// category components

export const CREATE_CATEGORY_COMPONENT = gql`
  mutation createCategoryComponent($component: CreateCategoryComponentInput!) {
    success: createCategoryComponent(component: $component)
  }
`
export const DESTROY_CATEGORY_COMPONENT = gql`
  mutation destroyCategoryComponent($componentId: ID!) {
    success: destroyCategoryComponent(componentId: $componentId)
  }
`

// element components

export const CREATE_ELEMENT_COMPONENT = gql`
  mutation createElementComponent($component: CreateElementComponentInput!) {
    success: createElementComponent(component: $component)
  }
`
export const DESTROY_ELEMENT_COMPONENT = gql`
  mutation destroyElementComponent($id: ID!, $parent: ID!) {
    success: destroyElementComponent(id: $id, parent: $parent)
  }
`
