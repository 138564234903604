import React, { Component } from 'react'
import { array, func } from 'prop-types'
import { Space } from 'shared/components'
import { MdAdd } from 'react-icons/md'
import { MdClose } from 'react-icons/md'
import get from 'lodash.get'
import { withStyles } from '@material-ui/core/styles'
import { Formik, Field } from 'formik'
import { isEmpty, contains, isNil } from 'utils'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Slide from '@material-ui/core/Slide'

const ErrorMessage = ({ message }) => (
  <p
    style={{
      color: 'crimson',
      fontSize: '.75rem',
      marginTop: 0,
      marginBottom: 16
    }}
  >
    {message}
  </p>
)

/**
 * AddTagDialog allows a user to create a new MenuTag while editing a Menu Element.
 */
class AddTagDialog extends Component {
  state = { open: false }

  handleOpenDialog = () => {
    this.setState({ open: true })
  }

  handleCloseDialog = () => {
    this.setState({ open: false })
  }

  render() {
    const { classes, currentTags, addTag, title } = this.props

    return (
      <div>
        <IconButton
          aria-label="add tag"
          color="primary"
          onClick={this.handleOpenDialog}
        >
          <MdAdd />
        </IconButton>

        <Dialog
          open={this.state.open}
          onClose={this.handleCloseDialog}
          TransitionComponent={Transition}
        >
          <AppBar
            className={classes.appBar}
            classes={{ colorPrimary: classes.colorPrimary }}
          >
            <Toolbar>
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'space-between'
                }}
              >
                <IconButton
                  color="primary"
                  classes={{
                    colorPrimary: classes.typographyColorPrimary
                  }}
                  onClick={() => {
                    this.handleCloseDialog()
                  }}
                  aria-label="Close"
                >
                  <MdClose />
                </IconButton>

                <Typography
                  align="center"
                  color="primary"
                  className={classes.flex}
                  classes={{
                    colorPrimary: classes.typographyColorPrimary
                  }}
                  variant="body2"
                >
                  {title || "Create Tag"}
                </Typography>

                <IconButton disabled={true} />
              </div>
            </Toolbar>
          </AppBar>

          <DialogContent>
            <Formik
              displayName="FormikAddTag"
              initialValues={{
                tagValue: ''
              }}
              onSubmit={values => {
                addTag(values.tagValue)
                this.handleCloseDialog()
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Field
                        name="tagValue"
                        validate={v => {
                          if (contains(currentTags, v))
                            return `"${v}" tag already added`
                        }}
                        render={({ field, form: { errors, touched } }) => (
                          <TextField fullWidth {...field} />
                        )}
                      />
                      <Button
                        disabled={isEmpty(values.tagValue) || !isEmpty(errors)}
                        style={{ textTransform: 'capitalize' }}
                        type="submit"
                        color="primary"
                      >
                        Add
                      </Button>
                    </div>
                    {!isNil(get(errors, 'tagValue')) && (
                      <ErrorMessage message={get(errors, 'tagValue')} />
                    )}
                    <Space direcion="x" value="half" />
                  </form>
                )
              }}
            </Formik>
          </DialogContent>
        </Dialog>
      </div>
    )
  }
}

AddTagDialog.propTypes = {
  /**
   * Array of existing tag attributes to filter through, so that UI can provide auto-complete when creating a new tag.
   */
  allTags: array,
  /**
   * Function to call when we want to push a newly created tag into the interface for tags to be created.
   */
  addTag: func.isRequired
}

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />
})

const styles = theme => {
  return {
    appBar: {
      position: 'relative'
    },
    colorPrimary: {
      backgroundColor: '#EDF0F2'
    },
    typographyColorPrimary: {
      color: '#636569'
    },
    textField: {
      margin: '0 auto'
    },
    flex: {
      flex: 1
    }
  }
}

export default withStyles(styles, { withTheme: true })(AddTagDialog)
