import get from 'lodash.get'
import { ifNil, isEmpty, isNil, update } from 'utils'

export const METRICS_ENUM_VALUES = [
  'CATERING',
  'HAS_UNRECOGNIZED_COMMAND',
  'ITEM_ADDED',
  'ORDER_VALIDATED',
  'REORDER',
  'SENT_TO_STORE',
  'TRANSACTION_COMPLETE',
  'TRANSACTION_MISSED'
]

export const PAYMENT_METHOD_ENUM_VALUES = ['PAY_NOW', 'PAY_IN_STORE']

export const OCCASIONS = ['DELIVERY', 'CURBSIDE', 'CARRY_OUT']

// makes 2 corrections to readback order
// 1. add in any missing selections
// 2. remove any ids from readback order where there is not a corresponding selection
const cleanReadbackOrder = (rbo, selections) => {
  const current = []

  // populate readback-order only if there is an existing selection 
  rbo.forEach(id => {
    if (selections.find(s => get(s, 'element.elementId') === id))
      current.push(id)
  })

  // add in any selections that were not apart of the initial readback order
  selections.forEach(s => {
    if (current.indexOf(s.element.elementId) === -1) current.push(s.element.elementId)
  })

  return current
}

// Takes a categoryComponent cc, and sorts selections by readback order
export const sortSelections = cc => {
  const unsortedSelections = Array.from(get(cc, 'selections', []))

  const readbackOrder = cleanReadbackOrder(
    Array.from(get(cc, 'proseHint.readbackOrder')),
    unsortedSelections
  )

  let sortedSelections = []

  readbackOrder.forEach(id =>
    sortedSelections.push(
      unsortedSelections.find(s => s.element.elementId === id)
    )
  )

  return sortedSelections
}

export const mutateCategoryComponentForFormik = c => {
  const clonedSelections = Array.from(get(c, 'selections', []))

  /*---------- fill in blanks for addDefault and minQty ----------*/
  update(c, 'addDefault', val => ifNil(val, false))
  update(c, 'maxQty', val => ifNil(val, ''))
  update(c, 'minSelectionCount', val => ifNil(val, ''))
  update(c, 'maxSelectionCount', val => ifNil(val, ''))

  /*---------- fill in any blanks for prose hint ----------*/
  if (isNil(get(c, 'proseHint'))) c.proseHint = {}

  if (isNil(get(c, 'proseHint.isOptionalUpsell')))
    c.proseHint.isOptionalUpsell = false

  if (isNil(get(c, 'proseHint.useUpToMax')))
    c.proseHint.useUpToMax = false

  if (isNil(get(c, 'proseHint.muteAll'))) c.proseHint.muteAll = false

  if (isNil(get(c, 'proseHint.muteReadback'))) c.proseHint.muteReadback = []

  if (isEmpty(get(c, 'proseHint.readbackOrder')))
    c.proseHint.readbackOrder = clonedSelections.map(s => s.element.elementId)

  if (isNil(get(c, 'proseHint.readbackQuantity')))
    c.proseHint.readbackQuantity = ''

  if (isNil(get(c, 'proseHint.helpStatement')))
    c.proseHint.helpStatement = ''

  /*---------- sort selections by readback order ----------*/
  const sortedSelections = sortSelections(c)

  c.selections = sortedSelections

  /*---------- use corrected readback order ----------*/
  c.proseHint.readbackOrder = cleanReadbackOrder(
    Array.from(get(c, 'proseHint.readbackOrder')),
    sortedSelections
  ) 
}

export const elementToComponent = (element, owner, category) => {
  return {
    antiElements: [],
    category: { ...category },
    componentId: `TBC${element.elementId}`,
    defaultValue: false,
    element: { ...element },
    owner: { ...owner }
  }
}
