import React, { Fragment } from 'react'
import styled from 'styled-components'
import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import { ifNil, combineMessages, compareUsingKey, renderFallback } from 'utils'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { string } from 'prop-types'
import { MdNavigateBefore } from 'react-icons/md'
import { useQuery } from 'react-apollo'
import { breakPoints } from 'shared/breakPoints'
import { useConceptLocations } from 'shared/hooks'
import get from 'lodash.get'
import isEmpty from 'lodash.isempty'
import GET_CATEGORY from 'pages/menu/graphql/queries/getCategory'
import GET_ELEMENTS from 'pages/menu/graphql/queries/getElements'

/**---------- styled components ----------*/
const CategoryHeader = styled.div.withConfig({ displayName: 'ElementHeader' })`
  display: flex,

  justify-content: center;
  margin-bottom: 16px;
  margin-top: 16px;
`

const Root = styled.div.withConfig({ displayName: 'ViewElement' })`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`

const CategoryAttributeList = styled.div.withConfig({
  displayName: 'ElementAttributeList'
})`
  margin: 0 auto;
  padding: 0 32px;

  @media ${breakPoints.muiLarge} {
    padding: 0;
    width: 1000px;
  }
`

const CategoryAttribute = styled.div.withConfig({
  displayName: 'ElementAttribute'
})`
  display: flex;
  margin: 16px 0px;
  min-height: 50px;
  justify-content: space-between;
`

const ButtonContainer = styled.div.withConfig({
  displayName: 'ButtonContainer'
})`
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
  position: fixed;
  width: 100%;
  z-index: 1099;

  @media ${breakPoints.muiLarge} {
    width: calc(100% - 265px);
  }
`

const HeaderSpacer = styled.div.withConfig({ displayName: 'HeaderSpacer' })`
  min-height: 36px;
`

const muiStyles = theme => ({
  buttonRoot: {
    color: 'var(--novo-light-gray)'
  },
  btnMinWidth: {
    minWidth: theme.spacing()
  }
})

/**---------- complementary components ----------*/
const Loading = () => (
  <Root>
    <CircularProgress />
  </Root>
)

/**---------- Main Component ----------*/
const ViewCategory = ({ categoryId, back, editCategory }) => {
  const { loading, error, data } = useQuery(GET_CATEGORY, {
    variables: {
      categoryId,
      conceptLocationsFilter: useConceptLocations().filter
    },
    fetchPolicy: 'network-only'
  })

  const {
    data: elementsData,
    error: elementsError,
    loading: elementsLoading
  } = useQuery(GET_ELEMENTS, {
    variables: { conceptLocationsFilter: useConceptLocations().filter },
    fetchPolicy: 'network-only'
  })

  const { loading: rLoading, error: rError, data: recursiveData } = useQuery(
    GET_CATEGORY,
    {
      variables: {
        categoryId,
        conceptLocationsFilter: useConceptLocations().filter,
        recursiveChildCategories: true,
        recursiveElements: true
      }
    }
  )

  /**---------- handle loading/error states here ----------*/
  if (error || rError || elementsError)
    throw new Error(combineMessages([error, elementsError, rError]))

  if (loading || rLoading || elementsLoading) return <Loading />

  const elementsForConcept = get(elementsData, 'elements', [])
  const elementById = id => elementsForConcept.find(e => e.elementId === id)

  /**---------- to be rendered when graphql query succeeds ----------*/
  const { category } = data
  const { category: recursiveCategory } = recursiveData

  const {
    childCategories,
    description,
    displayName,
    displayPlural,
    elementType,
    elements,
    lookupName,
    parent,
    proseHint
  } = category

  const upsellElements = (() => {
    const coll = get(proseHint, 'upsellElements', [])

    if (coll.length === 0) {
      return <Typography variant="body1">none</Typography>
    }

    const els = coll.map(id => {
      const el = elementById(id)
      return (
        <Typography align="right" variant="body1" key={id}>
          {el.displayName}
        </Typography>
      )
    })

    return <div>{els}</div>
  })()

  const allElements = elements
    .sort((a, b) => compareUsingKey(a, b, 'displayName'))
    .map(e => {
      return (
        <Typography key={e.elementId} variant="body1" align="right">
          {e.displayName} ({e.elementId})
        </Typography>
      )
    })

  const allChildCategories = childCategories.map(c => {
    return (
      <Typography key={c.categoryId} variant="body1" align="right">
        {c.displayName} ({c.categoryId})
      </Typography>
    )
  })

  const readbackElements = (() => {
    if (!isEmpty(get(proseHint, 'readbackOrder'))) {
      return get(proseHint, 'readbackOrder').map(id => {
        const match = recursiveCategory.elements.find(e => e.elementId === id)

        if (match) {
          return (
            <Typography key={id} variant="body1" align="right">
              {match.displayName} ({match.elementId})
            </Typography>
          )
        } else return null
      })
    } else return null
  })()

  const readbackCategories = (() => {
    if (!isEmpty(get(proseHint, 'readbackCategories'))) {
      return get(proseHint, 'readbackCategories').map(id => {
        const match = recursiveCategory.childCategories.find(
          c => c.categoryId === id
        )

        if (match) {
          return (
            <Typography key={id} variant="body1" align="right">
              {match.displayName} ({match.categoryId})
            </Typography>
          )
        } else return null
      })
    } else return null
  })()

  return (
    <Fragment>
      <ButtonContainer>
        <Button
          disableRipple
          onClick={() => back()}
          style={{ textTransform: 'capitalize' }}
        >
          <Typography
            style={{ color: 'var(--novo-light-gray)' }}
            variant="subtitle1"
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <MdNavigateBefore />
              Categories
            </div>
          </Typography>
        </Button>

        <Button
          disableRipple
          onClick={() => editCategory()}
          style={{ textTransform: 'capitalize' }}
        >
          <Typography
            style={{ color: 'var(--novo-light-gray)' }}
            variant="subtitle1"
          >
            Edit
          </Typography>
        </Button>
      </ButtonContainer>

      <HeaderSpacer />

      <CategoryHeader>
        <Typography align="center" variant="h6">
          {displayName}
        </Typography>
      </CategoryHeader>

      <CategoryAttributeList>
        <CategoryAttribute>
          <Typography variant="body2">Display Name:</Typography>

          <Typography variant="body1">{displayName}</Typography>
        </CategoryAttribute>

        <CategoryAttribute>
          <Typography variant="body2">Display Name Plural:</Typography>

          <Typography variant="body1">{displayPlural || 'none'}</Typography>
        </CategoryAttribute>

        <CategoryAttribute>
          <Typography variant="body2">Description</Typography>

          <div style={{ width: '40%' }}>
            <Typography variant="body1" align="right">
              {description || 'none'}
            </Typography>
          </div>
        </CategoryAttribute>

        <CategoryAttribute>
          <Typography variant="body2">Lookup Name:</Typography>

          <Typography variant="body1">{lookupName}</Typography>
        </CategoryAttribute>

        <CategoryAttribute>
          <Typography variant="body2">Element Type:</Typography>

          <Typography variant="body1">{elementType}</Typography>
        </CategoryAttribute>

        <CategoryAttribute>
          <Typography variant="body2">Category ID:</Typography>

          <Typography variant="body1">{categoryId}</Typography>
        </CategoryAttribute>

        <CategoryAttribute>
          <Typography variant="body2">Parent Category:</Typography>
          <Typography variant="body1">
            {renderFallback(get(parent, 'displayName'), 'none')}
          </Typography>
        </CategoryAttribute>

        <CategoryAttribute>
          <Typography variant="body2">Upsell Category:</Typography>
          <Typography variant="body1">
            {renderFallback(
              get(proseHint, 'upsellCategory.displayName'),
              'none'
            )}
          </Typography>
        </CategoryAttribute>

        <CategoryAttribute>
          <Typography variant="body2">Upsell Elements:</Typography>

          {upsellElements}
        </CategoryAttribute>

        <CategoryAttribute>
          <Typography variant="body2">Readback Quantity:</Typography>

          <Typography variant="body1">
            {ifNil(get(proseHint, 'readbackQuantity'), 'none')}
          </Typography>
        </CategoryAttribute>

        <CategoryAttribute>
          <Typography variant="body2">Ask Hint:</Typography>

          <Typography variant="body1">
            {ifNil(get(proseHint, 'askHint'), 'none')}
          </Typography>
        </CategoryAttribute>
        <CategoryAttribute>
          <Typography variant="body2">Say Hint:</Typography>

          <Typography variant="body1">
            {ifNil(get(proseHint, 'sayHint'), 'none')}
          </Typography>
        </CategoryAttribute>
        <CategoryAttribute>
          <Typography variant="body2">Mod Order:</Typography>

          <Typography variant="body1">
            {ifNil(get(proseHint, 'modOrder', 'none'), 'none')}
          </Typography>
        </CategoryAttribute>
        <CategoryAttribute>
          <Typography variant="body2">Element Readback Order:</Typography>

          <div>{readbackElements}</div>
        </CategoryAttribute>

        <CategoryAttribute>
          <Typography variant="body2">Category Readback Order:</Typography>

          <div>{readbackCategories}</div>
        </CategoryAttribute>

        <CategoryAttribute>
          <Typography variant="body2">Child Elements (A-Z):</Typography>

          <div>
            {allElements.length > 0 ? (
              allElements
            ) : (
              <Typography variant="body1" align="right">
                none
              </Typography>
            )}
          </div>
        </CategoryAttribute>

        <CategoryAttribute>
          <Typography variant="body2">Child Categories:</Typography>

          <div>
            {allChildCategories.length > 0 ? (
              allChildCategories
            ) : (
              <Typography variant="body1" align="right">
                none
              </Typography>
            )}
          </div>
        </CategoryAttribute>
      </CategoryAttributeList>
    </Fragment>
  )
}

ViewCategory.propTypes = {
  /**
   * The categoryID of the MenuCategory to get data for.
   */
  categoryId: string.isRequired
}

export default withStyles(muiStyles, { withTheme: true })(ViewCategory)
