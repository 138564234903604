import React, { useState } from 'react'
import Add from '@material-ui/icons/AddCircle'
import Dialog from '@material-ui/core/Dialog'
import { Space } from 'shared/components'
import { Formik } from 'formik'
import * as yup from 'yup'
import v from 'voca'
import { decode } from 'utils/graphql'
import get from 'lodash.get'
import { ifNil, isEmpty, selectKeys, update, contains } from 'utils'
import {
  mutateCategoryComponentForFormik,
  elementToComponent
} from 'shared/graphql/utils'
import { useQuery } from 'react-apollo'
import { useConceptLocations } from 'shared/hooks'
import { Container } from 'shared/components'
import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import ErrorMessage from 'pages/menu/components/ErrorMessage'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import Close from '@material-ui/icons/Close'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Switch from '@material-ui/core/Switch'
import AddSelections from 'pages/menu/components/review/AddSelections'
import GET_CATEGORIES from 'pages/menu/graphql/queries/getCategories'
import { GET_MENUS } from 'pages/menu/graphql/queries/menu'

/*---------- styles and styled components ----------*/
const styles = {
  paper: {
    width: 760,
    minHeight: 640,
    padding: '0px 16px'
  },
  dialogRoot: {
    paddingTop: 96
  },
  scrollPaper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start'
  }
}

/*---------- util fns ----------*/
const makeInitialValues = categoryId => {
  return {
    addDefault: false,
    categoryId,
    menus: [],
    minQty: 0,
    maxQty: '',
    isOptionalUpsell: false,
    muteAll: false,
    readbackQuantity: 0,
    selections: []
  }
}

/*---------- complementary components ----------*/
const Form = ({
  exit,
  submit,
  owner,
  category,
  currentModifierGroups,
  menus
}) => {
  const assignableMenus = menus.filter(m => {
    if (
      currentModifierGroups[m.id] &&
      currentModifierGroups[m.id].find(
        g => g.category.categoryId === category.categoryId
      )
    )
      return false
    else return true
  })

  return (
    <Formik
      displayName="CreateCategoryComponentForm"
      enableReinitialize
      initialValues={makeInitialValues(category.categoryId)}
      initialErrors={{ menus: 'at least one menu should be selected' }}
      validationSchema={yup.object().shape({
        menus: yup.array().min(1, 'at least one menu should be selected')
      })}
      onSubmit={(v, { setSubmitting }) => {
        const clone = { ...v }
        clone.componentId = `TBC${clone.categoryId}-${v.menus.join('-')}`
        clone.category = category

        clone.menus = v.menus.map(id => {
          return { id }
        })

        clone.proseHint = selectKeys(clone, [
          'isOptionalUpsell',
          'muteAll',
          'readbackQuantity'
        ])

        clone.priority = Object.keys(currentModifierGroups).length + 2

        update(clone, 'selections', coll => {
          return Array.from(coll).map(s =>
            elementToComponent(s, owner, category)
          )
        })

        mutateCategoryComponentForFormik(clone)
        exit()
        submit(clone)
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        errors,
        isSubmitting
      }) => {
        const setSelections = selections =>
          setFieldValue('selections', selections)

        return (
          <form style={{ flex: 1, position: 'relative' }}>
            <Container
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Container>
                <FormControl variant="standard">
                  <InputLabel id="menus">Menus</InputLabel>
                  <Select
                    multiple
                    labelId="menus"
                    name="menus"
                    value={values.menus}
                    renderValue={coll =>
                      coll
                        .map(id => menus.find(m => m.id === id).name)
                        .join(', ')
                    }
                    onChange={handleChange}
                    input={<Input error={isEmpty(assignableMenus)} fullWidth />}
                    style={{ width: 400 }}
                  >
                    {menus.map(m => (
                      <MenuItem
                        key={m.id}
                        value={m.id}
                        disabled={
                          !contains(
                            assignableMenus,
                            m.id,
                            assignable => assignable.id
                          )
                        }
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {v.titleCase(m.name)}
                          <Space direction="x" value="half" />
                          <span style={{ color: 'var(--novo-light-gray)' }}>
                            {` - #${decode(m.id).id}`}
                          </span>
                        </div>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <Space direction="y" value="eighth" />

                {isEmpty(assignableMenus) && (
                  <ErrorMessage
                    message={`${category.displayName} already exists in all menus`}
                  />
                )}
              </Container>

              <Space direction="y" value="one" />

              <Container
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={values.isOptionalUpsell}
                      color="primary"
                      name="isOptionalUpsell"
                      onChange={handleChange}
                    />
                  }
                  label={
                    <Container display="flex" alignItems="center">
                      <Tooltip title="Should we prompt user to make a selection for this category?">
                        <FormLabel>prompt for upsell</FormLabel>
                      </Tooltip>
                    </Container>
                  }
                />

                <FormControlLabel
                  control={
                    <Switch
                      checked={values.muteAll}
                      color="primary"
                      name="muteAll"
                      onChange={handleChange}
                    />
                  }
                  label={
                    <Container display="flex" alignItems="center">
                      <Tooltip title="Should we skip reading back selections for this category?">
                        <FormLabel>mute readback</FormLabel>
                      </Tooltip>
                    </Container>
                  }
                />
              </Container>
            </Container>

            <Space value="two" />

            <Container display="flex" justifyContent="space-between">
              <Container display="flex" flexDirection="column" flex="1">
                <FormGroup>
                  <FormLabel>Min/Max Quantities</FormLabel>

                  <FormHelperText>
                    The number of selections that may be made from the{' '}
                    {category.displayName} category.
                  </FormHelperText>

                  <Space value="one" />

                  <TextField
                    label="minimum"
                    name="minQty"
                    inputProps={{ type: 'number', min: '0' }}
                    value={values.minQty}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <Space value="half" />

                  <TextField
                    inputProps={{ type: 'number', min: '0' }}
                    label="maximum"
                    name="maxQty"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.maxQty}
                  />
                  <Space value="two" />
                </FormGroup>
                <FormGroup>
                  <FormLabel>Readback</FormLabel>

                  <FormHelperText>Configure readback parameters</FormHelperText>

                  <Space value="one" />

                  <TextField
                    inputProps={{ type: 'number', min: '0' }}
                    label="Readback Quantity"
                    name="readbackQuantity"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.readbackQuantity}
                  />
                </FormGroup>
              </Container>

              <Space value="two" direction="x" />

              <Container flex="2">
                <AddSelections
                  categoryId={category.categoryId}
                  setSelections={setSelections}
                  owner={owner}
                />
              </Container>
            </Container>

            <Space direction="y" value="three" />

            <Container
              display="flex"
              justifyContent="flex-end"
              position="absolute"
              bottom="0"
              width="100%"
            >
              <Button
                style={{ color: 'var(--novo-light-gray)' }}
                onClick={exit}
              >
                cancel
              </Button>

              <Space value="one" direction="x" />

              <Button
                disabled={isSubmitting || !isEmpty(errors)}
                color="primary"
                onClick={handleSubmit}
              >
                add
              </Button>
            </Container>
          </form>
        )
      }}
    </Formik>
  )
}

/*---------- main component ----------*/
const CreateCategoryComponent = ({
  classes,
  owner,
  submit,
  currentModifierGroups
}) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const toggleDialogOpen = () => setDialogOpen(!dialogOpen)
  const { filter } = useConceptLocations()
  const [activeCategory, setActiveCategory] = useState(null)
  const clearActiveCategory = () => setActiveCategory(null)
  const exit = () => {
    setActiveCategory(null)
    setDialogOpen(false)
  }

  const {
    data: categoriesData,
    error: categoriesError,
    loading: categoriesLoading
  } = useQuery(GET_CATEGORIES, {
    variables: {
      conceptId: get(filter, 'conceptId')
    },
    skip: get(filter, 'conceptId') === null
  })

  const { data: menusData, error: menusError } = useQuery(GET_MENUS, {
    variables: {
      concept: get(filter, 'conceptId')
    }
  })

  if (categoriesError) throw new Error(categoriesError)
  if (menusError) throw new Error(menusError)

  if (categoriesLoading || !categoriesData)
    return (
      <>
        <IconButton onClick={toggleDialogOpen}>
          <Tooltip title="create new modifier group">
            <Add />
          </Tooltip>
        </IconButton>

        <Dialog>
          <CircularProgress />
        </Dialog>
      </>
    )

  const categories = get(categoriesData, 'categories', [])

  return (
    <>
      <IconButton onClick={toggleDialogOpen}>
        <Tooltip title="create new modifier group">
          <Add />
        </Tooltip>
      </IconButton>

      <Dialog
        disableBackdropClick
        open={dialogOpen}
        onClose={exit}
        classes={{
          paper: classes.paper,
          root: classes.dialogRoot,
          scrollPaper: classes.scrollPaper
        }}
        maxWidth={false}
      >
        <Container
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Container>
            <IconButton onClick={exit}>
              <Close />
            </IconButton>
          </Container>
          <DialogTitle style={{ textAlign: 'center' }}>
            Create Modifier Group for {owner.displayName}
          </DialogTitle>
          <Container width="48px" />
        </Container>

        <Container display="flex" justifyContent="center">
          <FormControl variant="filled">
            <InputLabel id="activeCategory">Category</InputLabel>
            <Select
              labelId="activeCategory"
              value={activeCategory || ''}
              onChange={e => {
                clearActiveCategory()
                setActiveCategory(e.target.value)
              }}
              style={{ minWidth: 400 }}
            >
              {categories.map(c => (
                <MenuItem key={c.categoryId} value={c}>
                  <Container display="flex" alignItems="center">
                    <Typography style={{ color: 'var(--novo-dark-gray)' }}>
                      {c.displayName}
                      <span style={{ color: 'var(--novo-light-gray)' }}>
                        {` #${c.categoryId}`}
                      </span>
                    </Typography>
                    {!isEmpty(c.lookupName) && (
                      <>
                        <Space value="half" direction="x" />
                        <Typography style={{ color: 'var(--novo-light-gray)' }}>
                          ({ifNil(c.lookupName)})
                        </Typography>
                      </>
                    )}
                  </Container>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Container>

        <Space value="one" />

        <DialogContent
          style={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          {activeCategory && (
            <Container flex="1" display="flex" flexDirection="column">
              <Form
                currentModifierGroups={currentModifierGroups}
                menus={ifNil(get(menusData, 'menus'), [])}
                category={activeCategory}
                exit={exit}
                submit={submit}
                owner={owner}
              />
            </Container>
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}

export default withStyles(styles)(CreateCategoryComponent)
