import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { useQuery } from 'react-apollo'
import { compareStrings } from 'utils'
import { FixedSizeList as List } from 'react-window'
import { Space } from 'shared/components'
import { decode } from 'utils/graphql'
import Dialog from '@material-ui/core/Dialog'
import TextField from '@material-ui/core/TextField'
import Clear from '@material-ui/icons/Clear'
import Tooltip from '@material-ui/core/Tooltip'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import GET_LOCATIONS from 'shared/graphql/queries/getMenuLocations'
import v from 'voca'
import Fuse from 'fuse.js'

const fuzzyConfig = {
  shouldSort: true,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ['locationId', 'name'],
  threshold: 0.2
}

const fuzzyFind = (val, coll) => {
  const inputValue = val.trim().toLowerCase()
  const fuse = new Fuse(coll, fuzzyConfig)

  if (inputValue.length === 0) return []

  return fuse.search(inputValue)
}

const ViewMenu = ({ menu, classes, close, conceptId }) => {
  let allLocations = {}
  let menuLocations

  const [inputValue, setInputValue] = useState('')

  const { data, error } = useQuery(GET_LOCATIONS, {
    variables: { conceptId }
  })

  if (data) {
    data.menuLocations.forEach(l => (allLocations[l.locationId] = l))
    menuLocations = menu.locations.map(l => {
      return {
        ...allLocations[l.locationId],
        locationId: decode(l.locationId).id
      }
    })
    menuLocations.sort((a, b) => compareStrings(a.name, b.name))
  }

  const filtered = (() => {
    if (inputValue.trim().length === 0) return menuLocations
    else return fuzzyFind(inputValue, menuLocations)
  })()

  return (
    <Dialog
      open={true}
      onClose={close}
      classes={{
        paper: classes.paper,
        root: classes.dialogRoot,
        scrollPaper: classes.scrollPaper
      }}
    >
      <DialogTitle style={{ textAlign: 'center' }}>
        {v.titleCase(menu.name, ["'"])}
      </DialogTitle>

      <Typography
        style={{ textAlign: 'center', color: 'var(--novo-light-gray)' }}
      >
        <em>{menu.locations.length} locations</em>
      </Typography>

      <Space direction="y" value="one" />

      <DialogContent>
        {error && (
          <>
            <Typography>error while loading locations:</Typography>
            <Typography>{error.message}</Typography>
          </>
        )}

        {data && (
          <>
            <TextField
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      disabled={inputValue.length === 0}
                      onClick={() => setInputValue('')}
                    >
                      <Tooltip title="clear">
                        <Clear />
                      </Tooltip>
                    </IconButton>
                  </InputAdornment>
                )
              }}
              label="find location in menu"
              placeholder="search locations by name or ID"
              variant="outlined"
              value={inputValue}
              onChange={e => setInputValue(e.target.value)}
            />

            <Space direction="y" value="one" />

            <List height={420} itemCount={filtered.length} itemSize={48}>
              {({ index, style }) => {
                const l = filtered[index]

                return (
                  <ListItem style={style}>
                    <ListItemText primary={`${l.name} - # ${l.locationId}`} />
                  </ListItem>
                )
              }}
            </List>
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}

const styles = {
  dialogRoot: { paddingTop: 96 },
  paper: {
    minHeight: 140,
    minWidth: 560
  },
  scrollPaper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start'
  }
}

export default withStyles(styles)(ViewMenu)
