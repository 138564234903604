import React from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import { sortSelections } from 'shared/graphql/utils'
import SelectionsDialog from 'pages/menu/components/SelectionsDialog'

/**---------- util fns ----------*/
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const onDragEnd = (
  currentFormikOrder,
  updateOrder,
  { destination, source }
) => {
  if (!destination) return null

  updateOrder(reorder(currentFormikOrder, source.index, destination.index))
}

/**---------- main component ----------*/
const EditSelections = ({
  allSelections,
  categoryComponent,
  element,
  formikIndex,
  updateOrder,
  setFieldValue,
  values
}) => {
  const {
    componentId,
    category,
    proseHint: { muteReadback, readbackOrder }
  } = categoryComponent

  // sort selections by readbackOrder
  const sortedSelections = sortSelections(categoryComponent)

  return (
    <DragDropContext
      onDragEnd={result => onDragEnd(readbackOrder, updateOrder, result)}
    >
      <SelectionsDialog
        allSelections={allSelections}
        categoryComponent={categoryComponent}
        element={element}
        formikIndex={formikIndex}
        setFieldValue={setFieldValue}
        selections={sortedSelections}
        category={category}
        componentId={componentId}
        mutedSelections={muteReadback}
        values={values}
      />
    </DragDropContext>
  )
}

export default EditSelections
