import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import StyledLink from 'shared/components/StyledLink'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'

const TranscriptConsoleNavLinks = props => {
  const { classes, activeSection, baseURL } = props

  const consoleSections = ['phone calls', 'API', 'unrecognized']

  const consoleLinks = consoleSections.map(s => {
    if (s === activeSection) {
      return (
        <ListItem
          dense
          disableGutters
          selected
          classes={{
            root: classes.activeListItem,
            selected: classes.selected
          }}
          key={s}
        >
          <StyledLink to={`${baseURL}/console/${s}`}>
            <Typography
              variant="body2"
              color="primary"
              classes={{
                colorPrimary: classes.typographyColorPrimary
              }}
              style={{ paddingLeft: 8 }}
            >
              {s}
            </Typography>
          </StyledLink>
        </ListItem>
      )
    }

    return (
      <ListItem dense disableGutters key={s}>
        <StyledLink to={`${baseURL}/console/${s}`}>
          <Typography
            variant="body2"
            color="primary"
            classes={{
              colorPrimary: classes.typographyColorPrimary
            }}
            style={{ paddingLeft: 8 }}
          >
            {s}
          </Typography>
        </StyledLink>
      </ListItem>
    )
  })

  return (
    <Fragment>
      <Typography
        variant="subtitle1"
        color="primary"
        classes={{
          colorPrimary: classes.typographyColorPrimary
        }}
      >
        Transcript Console
      </Typography>

      <List dense style={{ zIndex: -1 }}>
        {consoleLinks}
      </List>
    </Fragment>
  )
}

const muiStyles = theme => ({
  typographyColorPrimary: {
    color: 'white'
  },
  activeListItem: {
    backgroundColor: '#6698C8',
    marginLeft: -8,
    paddingLeft: 8,
    width: 'calc(100% + 16px)'
  },
  selected: {
    '&$selected, &$selected:hover, &$selected:focus': {
      backgroundColor: '#6698C8'
    }
  }
})

export default withStyles(muiStyles, { withTheme: true })(
  TranscriptConsoleNavLinks
)
