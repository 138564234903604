import React from 'react'
import styled from 'styled-components'
import get from 'lodash.get'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormGroup from '@material-ui/core/FormGroup'
import Checkbox from '@material-ui/core/Checkbox'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Space from 'shared/components/Space'
import { contains, isEmpty, uniqueOn } from 'utils'
import { connect } from 'formik'

/*---------- styles and styled components ----------*/
const Root = styled.div`
  display: ${props => (props.active ? 'initial' : 'none')};
  margin: 0;
`

const OptionLabel = ({ selection }) => {
  const {
    element: { elementId, displayName, lookupName }
  } = selection

  const display = (
    <span>
      {displayName}{' '}
      {!isEmpty(lookupName) && (
        <span style={{ color: 'var(--novo-light-gray)' }}>[{lookupName}]</span>
      )}
    </span>
  )

  return (
    <ListItem disableGutters id={elementId}>
      <ListItemText primary={display} />
    </ListItem>
  )
}

const AntiElementSelection = ({
  fieldString,
  initiatingSelection,
  selection,
  checked,
  makeAntiElement,
  removeAntiElement
}) => {
  const {
    element: { elementId }
  } = selection

  return (
    <FormControlLabel
      key={elementId}
      control={
        <Checkbox
          color="primary"
          checked={checked}
          onChange={event => {
            event.target.checked 
              ? makeAntiElement(elementId)
              : removeAntiElement(elementId)
          }}
        />
      }
      label={<OptionLabel selection={selection} />}
      style={{ display: 'flex' }}
    />
  )
}

const EditSelection = ({
  selectionId,
  allSelections,
  active,
  formikIndex,
  formik: { values, setFieldValue }
}) => {
  if (!selectionId) return null

  const field = get(values, `modifierGroups[${formikIndex}]`)

  const selectionIndex = field.selections.findIndex(
    s => s.componentId === selectionId
  )

  const selection = field.selections[selectionIndex]

  const selectionFieldString = `modifierGroups[${formikIndex}].selections[${selectionIndex}]`

  const makeAntiElement = id => {
    const newAntiElements = {
      ...values.antiElements
    }

    // populate anti-element for the selection being edited
    newAntiElements[selection.element.elementId]
      ? (newAntiElements[selection.element.elementId] = [
          ...newAntiElements[selection.element.elementId],
          id
        ])
      : (newAntiElements[selection.element.elementId] = [id])

    // populate the mutual anti-element
    newAntiElements[id]
      ? newAntiElements[id] = [...newAntiElements[id], selection.element.elementId]
      : newAntiElements[id] = [selection.element.elementId]

    setFieldValue('antiElements', newAntiElements)
  }

  const removeAntiElement = id => {
    const newAntiElements = {
      ...values.antiElements
    }

    // remove anti-element for the selection being edited
    const indexForSelection = newAntiElements[selection.element.elementId].indexOf(id) 
    if (indexForSelection > -1) newAntiElements[selection.element.elementId].splice(indexForSelection, 1)

    // remove selection as anti-element 
    const indexOfSelection = newAntiElements[id] && newAntiElements[id].indexOf(selection.element.elementId)
    if (indexOfSelection > -1) newAntiElements[id].splice(indexOfSelection, 1)


    setFieldValue('antiElements', newAntiElements)
  }

  return (
    <Root active={active}>
      <Space value="one" />

      <div style={{ paddingLeft: 24 }}>
        <FormGroup>
          <Space direction="x" value="one" />

          <FormLabel>Anti-Elements</FormLabel>

          <Space direction="x" value="one" />

          <FormHelperText>
            When the selection{' '}
            <span style={{ fontFamily: 'Pangram Bold' }}>
              {selection.element.displayName}
            </span>{' '}
            is added to the ticket, the following items will be removed from the
            ticket:{' '}
          </FormHelperText>

          <List>
            {uniqueOn(allSelections, 'element.elementId')
              .filter(s => s.element.elementId !== selection.element.elementId)
              .map(s => {
                const checked = contains(
                  values.antiElements[selection.element.elementId] || [],
                  s.element.elementId
                )
                return (
                  <AntiElementSelection
                    key={s.componentId}
                    selection={s}
                    initiatingSelection={selection}
                    checked={checked}
                    makeAntiElement={makeAntiElement}
                    removeAntiElement={removeAntiElement}
                    fieldString={selectionFieldString}
                  />
                )
              })}
          </List>
        </FormGroup>
      </div>
    </Root>
  )
}

export default connect(EditSelection)
