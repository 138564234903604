import gql from 'graphql-tag'

export default gql`
  query getCategory(
    $categoryId: ID!
    $recursiveElements: Boolean
    $recursiveChildCategories: Boolean
    $conceptLocationsFilter: ConceptLocationsFilter!
  ) {
    category: menuCategory(
      categoryId: $categoryId
      recursiveElements: $recursiveElements
      recursiveChildCategories: $recursiveChildCategories
      conceptLocationsFilter: $conceptLocationsFilter
    ) {
      ...categoryFields
      childCategories(recursive: $recursiveChildCategories) {
        ...categoryFields
      }
      elements(recursive: $recursiveElements) {
        elementId
        displayName
        displayPlural
        description
        lookupName
      }
      parent {
        ...categoryFields
      }
    }
  }

  fragment categoryFields on MenuCategory {
    categoryId
    displayName
    lookupName
    description
    displayPlural
    elementType
    proseHint {
      askHint
      sayHint
      modOrder
      readDescription
      readbackCategories
      readbackOrder
      readbackQuantity
      upsellCategory {
        categoryId
        displayName
        displayPlural
        elementType
      }
      upsellElements
    }
  }
`
