import React, { useState, useRef } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import get from 'lodash.get'
import { decode } from 'utils/graphql'
import { withStyles } from '@material-ui/core/styles'
import { Field } from 'formik'
import { Container } from 'shared/components'
import { contains, isNil, ifNil, notNil, isEmpty, compareNums } from 'utils'
import { elementToComponent } from 'shared/graphql/utils'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import {
  MdClose,
  MdDragHandle,
  MdStarBorder,
  MdStar,
  MdVolumeOff,
  MdVolumeUp
} from 'react-icons/md'
import ArrowForwardIOS from '@material-ui/icons/ArrowForwardIos'
import ArrowBackIOS from '@material-ui/icons/ArrowBackIos'
import Destroy from '@material-ui/icons/Delete'
import Settings from '@material-ui/icons/Settings'
import { breakPoints } from 'shared/breakPoints'
import SwipeableViews from 'react-swipeable-views'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Space from 'shared/components/Space'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import RootRef from '@material-ui/core/RootRef'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import EditSelection from 'pages/menu/components/EditSelection'
import AddSelections from 'pages/menu/components/review/AddSelections'

/*---------- styles and styled components ----------*/
const styles = {
  iconColorPrimary: {
    color: 'var(--novo-dark-gray)'
  },
  dialogRoot: {
    paddingTop: 16
  },
  paper: {
    width: 800,
    height: '90vh',
    position: 'relative'
  },
  scrollPaper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start'
  },
  iconColorSecondary: {
    color: 'var(--novo-light-gray)',
    '&:hover': {
      backgroundColor: 'rgba(0, 188, 112, 0.08)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent'
      }
    }
  }
}

const SelectionsContainer = styled.div.withConfig({
  displayName: 'Selections'
})`
  @media ${breakPoints.muiLarge} {
    margin: 0 auto;
    max-width: 620px;
  }
`

const SelectionContainer = styled.div.withConfig({ displayName: 'Selection' })`
  background-color: white;
  display: flex;
  align-items: center;
  min-height: 56px;
  padding-left: 16px;
`

/*---------- complementary components ----------*/
const portal = document.createElement('div')

if (!document.body) throw new Error('body not ready for portal creation!')

document.body.appendChild(portal)

const ErrorMessage = ({ message }) => (
  <p
    style={{
      color: 'crimson',
      fontSize: '.75rem',
      margin: 0
    }}
  >
    {message}
  </p>
)

const DisplayAndLookupName = ({ selection }) => {
  const { displayName, lookupName, elementId } = selection

  return (
    <span>
      {displayName}{' '}
      {!isEmpty(lookupName) && (
        <span style={{ color: 'var(--novo-light-gray)' }}>[{lookupName}]</span>
      )}
      &ensp;
      <span style={{ color: "var(--novo-light-gray"}}>#{elementId}</span>
    </span>
  )
}

const PortalAwareSelection = ({ provided, snapshot, selection }) => {
  const {
    classes,
    element,
    edit,
    muteAll,
    destroySelection,
    defaultValue,
    muted,
    toggleMuted,
    toggleDefaultValue
  } = selection

  const usePortal = snapshot.isDragging

  const child = (
    <SelectionContainer ref={provided.innerRef} {...provided.draggableProps}>
      {defaultValue ? (
        <Tooltip title="default selection">
          <IconButton
            aria-label="remove as default"
            color="primary"
            onClick={() => {
              toggleDefaultValue()
            }}
          >
            <MdStar />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="not a default selection">
          <IconButton
            aria-label="set as default"
            classes={{
              colorSecondary: classes.iconColorSecondary
            }}
            color="secondary"
            onClick={() => {
              toggleDefaultValue()
            }}
          >
            <MdStarBorder />
          </IconButton>
        </Tooltip>
      )}

      {muteAll && (
        <IconButton
          aria-label="set as default"
          classes={{
            colorSecondary: classes.iconColorSecondary
          }}
          disabled={true}
          color="secondary"
          onClick={() => {
            toggleMuted()
          }}
        >
          <MdVolumeOff />
        </IconButton>
      )}

      {!muteAll && muted && (
        <Tooltip title="readback off">
          <IconButton
            aria-label="set as default"
            classes={{
              colorSecondary: classes.iconColorSecondary
            }}
            color="secondary"
            onClick={() => {
              toggleMuted()
            }}
          >
            <MdVolumeOff />
          </IconButton>
        </Tooltip>
      )}

      {!muteAll && !muted && (
        <Tooltip title="readback on">
          <IconButton
            aria-label="mute selection"
            classes={{
              colorPrimary: classes.iconColorPrimary
            }}
            color="primary"
            onClick={() => {
              toggleMuted()
            }}
          >
            <MdVolumeUp />
          </IconButton>
        </Tooltip>
      )}

      <Space direction="x" value="one" />

      <DisplayAndLookupName selection={element} />

      <Container display="flex" justifyContent="flex-end" flex="1">
        <IconButton onClick={() => destroySelection(selection)}>
          <Tooltip title="delete selection">
            <Destroy />
          </Tooltip>
        </IconButton>

        <Space value="one" direction="x" />

        {muteAll ? (
          <>
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'flex-end',
                marginRight: 24
              }}
            >
              <MdDragHandle
                style={{
                  color: 'var(--novo-light-gray)',
                  height: 24,
                  width: 24
                }}
              />
            </div>
            <div style={{ display: 'none' }} {...provided.dragHandleProps} />
          </>
        ) : (
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'flex-end',
              marginRight: 24
            }}
            {...provided.dragHandleProps}
          >
            <MdDragHandle style={{ height: 24, width: 24 }} />
          </div>
        )}

        <IconButton onClick={() => edit(selection)}>
          <ArrowForwardIOS />
        </IconButton>
      </Container>
    </SelectionContainer>
  )

  if (!usePortal) return child

  return ReactDOM.createPortal(child, portal)
}

const SelectionRaw = selection => {
  const { componentId, groupIndex } = selection
  return (
    <Draggable draggableId={componentId} index={groupIndex}>
      {(provided, snapshot) => {
        return (
          <PortalAwareSelection
            provided={provided}
            snapshot={snapshot}
            selection={selection}
          />
        )
      }}
    </Draggable>
  )
}

const Selection = withStyles(styles)(SelectionRaw)

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" {...props} ref={ref} />
))

/*---------- main component ----------*/
const SelectionsDialog = ({
  allSelections,
  selections,
  categoryComponent,
  componentId,
  classes,
  element,
  category,
  mutedSelections,
  formikIndex,
  setFieldValue,
  values
}) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [viewIndex, setViewIndex] = useState(0)
  const [editingSelection, setEditingSelection] = useState(null)
  const field = get(values, `modifierGroups[${formikIndex}]`)
  const fieldString = `modifierGroups[${formikIndex}]`
  const dialogContent = useRef(null)
  const muteAll = ifNil(get(field, 'proseHint.muteAll'), false)

  const exitEditing = () => {
    setEditingSelection(null)
    setViewIndex(0)
    if (dialogContent.current) dialogContent.current.scrollTop = 0
  }

  const editSelection = selection => {
    setEditingSelection(selection)
    setViewIndex(1)
    if (dialogContent.current) dialogContent.current.scrollTop = 0
  }

  const destroySelectionAndUpdateAntiElements = (
    selection,
    currentAntiElements,
    currentModifierGroups
  ) => {
    const newAntiElements = { ...currentAntiElements }

    if (
      currentModifierGroups.filter(g =>
        g.selections.find(
          s => s.element.elementId === selection.element.elementId
        )
      ).length === 1
    ) {
      delete newAntiElements[selection.element.elementId]
      for (const elementId in newAntiElements) {
        if (
          newAntiElements[elementId].indexOf(selection.element.elementId) > -1
        ) {
          newAntiElements[elementId].splice(
            newAntiElements[elementId].indexOf(selection.element.elementId),
            1
          )
        }
      }
    }

    const newReadbackOrder = Array.from(field.proseHint.readbackOrder)
    const rboIndex = newReadbackOrder.indexOf(selection.element.elementId)
    newReadbackOrder.splice(rboIndex, 1)

    const newSelections = Array.from(field.selections)
    const selIndex = newSelections.findIndex(
      s => s.element.elementId === selection.element.elementId
    )

    newSelections.splice(selIndex, 1)

    setFieldValue('antiElements', newAntiElements)
    setFieldValue(`${fieldString}.proseHint.readbackOrder`, newReadbackOrder)
    setFieldValue(`${fieldString}.selections`, newSelections)
  }

  const title = notNil(editingSelection)
    ? `Editing ${editingSelection.element.displayName}`
    : `Editing ${category.displayName} for ${element.displayName}`

  return (
    <div>
      <IconButton
        aria-label="add tag"
        classes={{
          colorPrimary: classes.iconColorPrimary
        }}
        color="primary"
        onClick={() => setDialogOpen(true)}
      >
        <Tooltip title="edit selections">
          <Settings />
        </Tooltip>
      </IconButton>

      <Dialog
        classes={{
          paper: classes.paper,
          root: classes.dialogRoot,
          scrollPaper: classes.scrollPaper
        }}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth={false}
        TransitionComponent={Transition}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            minHeight: 96,
            justifyContent: 'center'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              flex: 1,
              alignItems: 'flex-start'
            }}
          >
            <Space value="one" direction="x" />

            <IconButton
              onClick={() => {
                setDialogOpen(false)
                setEditingSelection(null)
                setViewIndex(0)
              }}
            >
              <MdClose />
            </IconButton>
          </div>

          <DialogTitle style={{ textAlign: 'center', flex: 4 }}>
            {title}

            <Typography variant="body2">
              Menus:{' '}
              {categoryComponent.menus
                .map(m => decode(m.id).id)
                .sort(compareNums)
                .join(', ')}
            </Typography>
          </DialogTitle>

          <div style={{ flex: 1 }} />
        </div>

        {editingSelection && (
          <div
            style={{
              border: '2px solid rgba(0, 0, 0, .1)',
              borderRadius: 8,
              backgroundColor: '#f7f7f7',
              display: 'flex',
              alignItems: 'center',
              margin: '0px 16px',
              padding: 4
            }}
          >
            <IconButton onClick={exitEditing}>
              <ArrowBackIOS viewBox="-4 0 24 24" />
            </IconButton>

            <Space value="half" direction="x" />

            <Typography variant="subtitle1" style={{ flex: 1 }}>
              {category.displayName}
            </Typography>
          </div>
        )}

        <RootRef rootRef={dialogContent}>
          <DialogContent>
            <SwipeableViews index={viewIndex}>
              <div>
                <Space value="one" />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}
                >
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        onChange={(e, checked) =>
                          setFieldValue(
                            fieldString.concat('proseHint.isOptionalUpsell'),
                            checked
                          )
                        }
                        checked={ifNil(
                          get(field, 'proseHint.isOptionalUpsell'),
                          false
                        )}
                      />
                    }
                    label="prompt for upsell"
                    labelPlacement="start"
                    style={{
                      width: 360,
                      justifyContent: 'space-between'
                    }}
                  />
                  <Space value="half" />

                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        onChange={(e, checked) =>
                          setFieldValue(
                            fieldString.concat('partitionable'),
                            checked
                          )
                        }
                        checked={get(field, 'partitionable')}
                      />
                    }
                    label="partitionable"
                    labelPlacement="start"
                    style={{
                      width: 360,
                      justifyContent: 'space-between'
                    }}
                  />

                  <Space value="half" />

                  <Field name={fieldString.concat('addDefault')}>
                    {({ field: formikField, form: { errors } }) => {
                      return (
                        <>
                          <FormControlLabel
                            control={
                              <Switch
                                color="primary"
                                checked={field.addDefault}
                                {...formikField}
                              />
                            }
                            label="automatically add default selections"
                            labelPlacement="start"
                            style={{
                              width: 360,
                              justifyContent: 'space-between'
                            }}
                          />
                          <div
                            style={{
                              width: 360,
                              display: 'flex',
                              minHeight: 16,
                              alignItems: 'center'
                            }}
                          >
                            {notNil(
                              get(errors, fieldString.concat('addDefault'))
                            ) && (
                              <>
                                <Space direction="x" value="one" />
                                <ErrorMessage
                                  message={get(
                                    errors,
                                    fieldString.concat('addDefault')
                                  )}
                                />
                                <Space value="half" />
                              </>
                            )}
                          </div>
                        </>
                      )
                    }}
                  </Field>
                  <Space value="half" />
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        onChange={(e, checked) =>
                          setFieldValue(
                            fieldString.concat('proseHint.muteAll'),
                            checked
                          )
                        }
                        checked={muteAll}
                      />
                    }
                    label="mute readback for all selections"
                    labelPlacement="start"
                    style={{
                      width: 360,
                      justifyContent: 'space-between'
                    }}
                  />

                  <Space value="half" />

                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        onChange={(e, checked) =>
                          setFieldValue(
                            fieldString.concat('proseHint.useUpToMax'),
                            checked
                          )
                        }
                        checked={get(field, 'proseHint.useUpToMax')}
                      />
                    }
                    label="prompt with up to `max-quantity`"
                    labelPlacement="start"
                    style={{
                      width: 360,
                      justifyContent: 'space-between'
                    }}
                  />

                  <Space value="half" />

                  <div style={{ width: 360, display: 'flex' }}>
                    <Space direction="x" value="one" />

                    <div style={{ minHeight: 48, flex: 1 }}>
                      <Field
                        name={`modifierGroups[${formikIndex}].proseHint.helpStatement`}
                      >
                        {({ field }) => {
                          return (
                            <TextField
                              label="Help Statement"
                              fullWidth
                              multiline
                              rowsMax={2}
                              style={{
                                alignSelf: 'center',
                                display: 'block'
                              }}
                              {...field}
                            />
                          )
                        }}
                      </Field>
                    </div>
                  </div>

                  <Space value="one" />

                  <div style={{ width: 360, display: 'flex' }}>
                    <Space direction="x" value="one" />
                    <div style={{ minHeight: 48 }}>
                      {!muteAll && (
                        <>
                          <Field
                            name={`modifierGroups[${formikIndex}].proseHint.readbackQuantity`}
                          >
                            {({ field }) => {
                              return (
                                <TextField
                                  label="Readback Quantity"
                                  inputProps={{ type: 'number', min: '0' }}
                                  style={{
                                    alignSelf: 'center',
                                    display: 'block'
                                  }}
                                  {...field}
                                />
                              )
                            }}
                          </Field>
                        </>
                      )}
                    </div>
                  </div>

                  <Space value="three" />

                  <Container width="620px" pl={3}>
                    <AddSelections
                      batchUpdate
                      categoryId={category.categoryId}
                      setSelections={elements => {
                        const currentSelections = Array.from(
                          values.modifierGroups[formikIndex].selections
                        )

                        const newSelections = elements.map(e =>
                          elementToComponent(e, element, category)
                        )

                        const currentRBO = Array.from(
                          values.modifierGroups[formikIndex].proseHint
                            .readbackOrder
                        )

                        const newIds = elements.map(e => e.elementId)

                        setFieldValue(
                          `${fieldString}.selections`,
                          currentSelections.concat(newSelections)
                        )
                        setFieldValue(
                          `${fieldString}.proseHint.readbackOrder`,
                          currentRBO.concat(newIds)
                        )
                      }}
                      ignore={selections}
                      owner={element}
                    />
                  </Container>
                </div>

                <Space value="two" />

                <Droppable droppableId={componentId}>
                  {(provided, snapshot) => {
                    return (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        <SelectionsContainer>
                          {selections.map((s, idx) => {
                            const {
                              element: { elementId },
                              defaultValue
                            } = s

                            const muted =
                              contains(mutedSelections, elementId) || muteAll

                            const coercedDefaultValue = isNil(defaultValue)
                              ? false
                              : defaultValue

                            return (
                              <Selection
                                key={s.componentId}
                                destroySelection={selection =>
                                  destroySelectionAndUpdateAntiElements(
                                    selection,
                                    values.antiElements,
                                    values.modifierGroups
                                  )
                                }
                                groupIndex={idx}
                                edit={selection => editSelection(selection)}
                                muted={muted}
                                muteAll={muteAll}
                                toggleMuted={() => {
                                  const newMuteReadback = Array.from(
                                    mutedSelections
                                  )
                                  if (muted) {
                                    newMuteReadback.splice(
                                      newMuteReadback.indexOf(elementId),
                                      1
                                    )
                                    setFieldValue(
                                      `modifierGroups[${formikIndex}].proseHint.muteReadback`,
                                      newMuteReadback
                                    )
                                  } else {
                                    newMuteReadback.push(elementId)
                                    setFieldValue(
                                      `modifierGroups[${formikIndex}].proseHint.muteReadback`,
                                      newMuteReadback
                                    )
                                  }
                                }}
                                toggleDefaultValue={() => {
                                  const currentIndex = values.modifierGroups[
                                    formikIndex
                                  ].selections.indexOf(s)
                                  setFieldValue(
                                    `modifierGroups[${formikIndex}].selections[${currentIndex}].defaultValue`,
                                    !coercedDefaultValue
                                  )
                                }}
                                {...s}
                              />
                            )
                          })}
                        </SelectionsContainer>
                        {provided.placeholder}
                      </div>
                    )
                  }}
                </Droppable>
              </div>

              <EditSelection
                active={viewIndex === 1}
                formikIndex={formikIndex}
                allSelections={allSelections}
                selectionId={get(editingSelection, 'componentId')}
              />
            </SwipeableViews>
          </DialogContent>
        </RootRef>
      </Dialog>
    </div>
  )
}

export default withStyles(styles)(SelectionsDialog)
