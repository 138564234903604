import React, { useState, Fragment } from 'react'
import styled from 'styled-components'
import { string } from 'prop-types'
import { ifNil } from 'utils'
import { compareUsingKey, update, isEmpty } from 'utils'
import get from 'lodash.get'
import { Formik, Field } from 'formik'
import { useQuery, useMutation } from 'react-apollo'
import * as yup from 'yup'
import Space from 'shared/components/Space'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContentText from '@material-ui/core/DialogContentText'
import FormGroup from '@material-ui/core/FormGroup'
import Typography from '@material-ui/core/Typography'
import { selectKeys, removeKeys, combineMessages } from 'utils'
import { breakPoints } from 'shared/breakPoints'
import { useConceptLocations } from 'shared/hooks'
import ElementDnd from 'pages/menu/components/categories/ElementDnd'
import EditUpsellElements from 'pages/menu/components/categories/EditUpsellElements'
import CategoryDnd from 'pages/menu/components/categories/CategoryDnd'
import GET_CATEGORY from 'pages/menu/graphql/queries/getCategory'
import GET_CATEGORIES from 'pages/menu/graphql/queries/getCategories'
import UPDATE_MENU_CATEGORY from 'pages/menu/graphql/mutations/updateCategory'

/**---------- styles and styled components ----------*/
const Root = styled.div.withConfig({ displayName: 'EditCategoryRoot' })`
  align-items: ${props => (props.centerAll ? 'center' : 'initial')};
  display: ${props => (props.centerAll ? 'flex' : 'initial')};
  justify-content: ${props => (props.centerAll ? 'center' : 'initial')};
  height: 100%;
  padding-bottom: 24px;
`

const styles = {
  textFieldRoot: {
    marginBottom: 16
  },
  textFieldExtraBottomMargin: {
    marginBottom: 20
  }
}

const HeaderFixed = styled.div.withConfig({ displayName: 'HeaderFixed' })`
  background-color: white;
  display: flex;
  justify-content: center;
  left: 0;
  margin-top: -2px
  min-height: 36px;
  padding-top: 8px;
  position: fixed;
  width: 100%;
  z-index: 1099;

  @media ${breakPoints.muiLarge} {
    margin-left: 265px;
    width: calc(100% - 265px);
  }
`

const HeaderSpacer = styled.div.withConfig({ displayName: 'HeaderSpacer' })`
  min-height: 36px;
`

const ButtonContainer = styled.div.withConfig({
  displayName: 'ButtonContainer'
})`
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 8px 0px;
  position: fixed;
  width: 100%;
  z-index: 1099;

  @media ${breakPoints.muiLarge} {
    width: calc(100% - 265px);
  }
`

/**---------- util fns ----------*/
// takes a Category and returns an object to be passed to Formik.initialValues
const makeInitialValues = category => ({
  askHint: ifNil(get(category, 'proseHint.askHint'), ''),
  description: category.description || '',
  displayName: category.displayName,
  displayPlural: category.displayPlural || '',
  elementType: category.elementType,
  lookupName: category.lookupName || '',
  modOrder: ifNil(get(category, 'proseHint.modOrder'), ''),
  parentId: category.parentId ? category.parentId : 'null',
  readDescription: ifNil(get(category, 'proseHint.readDescription'), false),
  readbackQuantity: ifNil(get(category, 'proseHint.readbackQuantity'), ''),
  readbackCategories: ifNil(get(category, 'proseHint.readbackCategories', [])),
  readbackOrder: ifNil(get(category, 'proseHint.readbackOrder'), []),
  sayHint: ifNil(get(category, 'proseHint.sayHint'), ''),
  upsellElements: ifNil(get(category, 'proseHint.upsellElements'), []),
  upsellCategory: ifNil(
    get(category, 'proseHint.upsellCategory.categoryId'),
    ''
  )
})

// uses Yup to define a validation schema for EditCategory
const validationSchema = yup.object().shape({
  askHint: yup.string(),
  displayName: yup.string().required('a display name is required'),
  displayPlural: yup.string(),
  lookupName: yup.string(),
  modOrder: yup
    .number()
    .integer('mod order should not have a decimal')
    .min(0, 'mod order should be between 0 and 10')
    .max(10, 'mod order should be between 0 and 10'),
  readbackQuantity: yup
    .number()
    .integer('readback quantity should not have a decimal')
    .positive('readback quantity should be positive'),
  readbackOrder: yup.array().of(yup.number()),
  readbackCategories: yup.array().of(yup.number()),
  sayHint: yup.string(),
  upsellElements: yup.array().of(yup.number()),
  upsellCategory: yup.string()
})

const buildMutationVariables = (values, { categoryId }) => {
  if (values.parentId === 'null') delete values.parentId

  const clone = { ...values }

  update(clone, 'modOrder', val => (val !== '' ? parseInt(val, 10) : 0))

  update(clone, 'readbackQuantity', val => (val !== '' ? parseInt(val, 10) : 0))

  const proseHintKeys = [
    'askHint',
    'sayHint',
    'modOrder',
    'readDescription',
    'readbackCategories',
    'readbackQuantity',
    'readbackOrder',
    'upsellCategory',
    'upsellElements'
  ]

  const proseHint = selectKeys(clone, proseHintKeys)

  return {
    variables: {
      category: {
        ...removeKeys(clone, proseHintKeys),
        categoryId,
        proseHint
      }
    }
  }
}

/**---------- main component ----------*/
const EditCategory = ({ categoryId, classes, exit }) => {
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertText, setAlertText] = useState('')
  const { filter } = useConceptLocations()
  const {
    data: categoriesData,
    error: categoriesError,
    loading: categoriesLoading
  } = useQuery(GET_CATEGORIES, {
    variables: {
      conceptId: get(filter, 'conceptId')
    },
    skip: get(filter, 'conceptId') === null
  })
  const {
    loading: categoryLoading,
    error: categoryError,
    data: categoryData
  } = useQuery(GET_CATEGORY, {
    variables: {
      categoryId,
      conceptLocationsFilter: filter
    }
  })

  const {
    loading: recursiveLoading,
    error: recursiveError,
    data: recursiveData
  } = useQuery(GET_CATEGORY, {
    variables: {
      categoryId,
      recursiveElements: true,
      recursiveCategories: true,
      conceptLocationsFilter: filter
    }
  })

  const [updateCategory, { error: mutationError }] = useMutation(
    UPDATE_MENU_CATEGORY,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_CATEGORIES,
          variables: { conceptId: get(filter, 'conceptId') }
        }
      ]
    }
  )

  /**---------- handle loading and errors here ----------*/
  if (categoryError || recursiveError || categoriesError)
    throw new Error(
      combineMessages([categoryError, recursiveError, categoriesError])
    )

  if (
    categoryLoading ||
    recursiveLoading ||
    categoriesLoading ||
    !categoriesData ||
    !categoryData ||
    !recursiveData
  )
    return <Root children={<CircularProgress />} centerAll />

  const { category } = categoryData
  const { categories } = categoriesData

  /**---------- to be rendered when graphql query(s) resolve ----------*/
  const categoryOptions = categories
    .sort((a, b) => compareUsingKey(a, b, 'displayName'))
    .map(c => {
      if (c.categoryId === category.categoryId) {
        return (
          <option
            disabled
            key={c.categoryId}
            value={parseInt(c.categoryId, 10)}
          >
            {c.displayName}
          </option>
        )
      }

      return (
        <option key={c.categoryId} value={parseInt(c.categoryId, 10)}>
          {c.displayName}
        </option>
      )
    })

  return (
    <Root>
      <Dialog open={alertOpen}>
        <DialogTitle>Category Update Results</DialogTitle>

        <DialogContentText style={{ paddingLeft: 24 }}>
          {alertText}
        </DialogContentText>

        <DialogActions>
          <Button
            onClick={() => {
              if (mutationError) {
                setAlertOpen(false)
                return null
              }
              setAlertOpen(false)
              exit()
              return null
            }}
            color="primary"
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <Formik
        displayName="Edit Category"
        initialValues={makeInitialValues(category)}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            await updateCategory(buildMutationVariables(values, category))
            setAlertText('Category succesfully updated')
            setAlertOpen(true)
          } catch (err) {
            setAlertText(err.message)
            setAlertOpen(true)
          }
          setSubmitting(false)
        }}
      >
        {({
          values,
          initialValues,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setValues,
          setFieldError
        }) => {
          const invalidDescription = isEmpty(values.description) && values.readDescription === true

          return (
            <Fragment>
              <ButtonContainer>
                <Button
                  disableRipple
                  onClick={() => exit()}
                  style={{ textTransform: 'capitalize' }}
                >
                  <Typography
                    style={{ color: 'var(--novo-light-gray)' }}
                    variant="subtitle1"
                  >
                    Cancel
                  </Typography>
                </Button>
                <Button
                  color="primary"
                  disableRipple
                  type="submit"
                  disabled={isSubmitting || Object.keys(errors).length !== 0 || invalidDescription}
                  onClick={handleSubmit}
                  style={{ textTransform: 'capitalize' }}
                >
                  <Typography color="inherit" variant="subtitle1">
                    Save
                  </Typography>
                </Button>
              </ButtonContainer>

              <HeaderSpacer />

              <form
                style={{
                  margin: '0 0',
                  padding: '0 16px',
                  paddingTop: 16,
                  paddingBottom: 24
                }}
                onSubmit={handleSubmit}
              >
                <HeaderFixed>
                  <Typography variant="h6" style={{ textAlign: 'center' }}>
                    <em>Editing {category.displayName}</em>
                  </Typography>
                </HeaderFixed>

                <HeaderSpacer />

                <TextField
                  classes={{
                    root: classes.textFieldRoot
                  }}
                  fullWidth
                  label="Display Name"
                  type="text"
                  name="displayName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.displayName}
                />
                {errors.displayName && touched.displayName && (
                  <p
                    style={{
                      color: 'red',
                      fontSize: 12,
                      textAlign: 'right'
                    }}
                  >
                    {errors.displayName}
                  </p>
                )}

                <TextField
                  classes={{
                    root: classes.textFieldRoot
                  }}
                  fullWidth
                  label="Display Name Plural"
                  type="text"
                  name="displayPlural"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.displayPlural}
                />

                <TextField
                  fullWidth
                  classes={{
                    root: classes.textFieldRoot
                  }}
                  label="Lookup Name"
                  type="text"
                  name="lookupName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.lookupName}
                />

                {errors.lookupName && touched.lookupName && (
                  <p
                    style={{
                      color: 'red',
                      fontSize: 12,
                      textAlign: 'right'
                    }}
                  >
                    {errors.lookupName}
                  </p>
                )}

                <TextField
                  fullWidth
                  label="Description"
                  onChange={handleChange}
                  multiline
                  rowsMax="4"
                  name="description"
                  value={values.description}
                />

                {invalidDescription && (
                  <p
                    style={{
                      color: 'red',
                      fontSize: 12,
                      textAlign: 'left'
                    }}
                  >
                    a description is required when Read Description is true
                  </p>
                )}

                <Space direction="y" value="half" />

                <Field name="readDescription">
                  {({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          name={field.name}
                          onChange={handleChange}
                          checked={values.readDescription}
                        />
                      }
                      label="Read Description Instead of Items List"
                    />
                  )}
                </Field>

                <Space direction="y" value="one" />

                <TextField
                  classes={{
                    root: classes.textFieldRoot
                  }}
                  fullWidth
                  select
                  label="Parent Category"
                  onChange={handleChange}
                  name="parentId"
                  SelectProps={{
                    native: true
                  }}
                  defaultValue={values.parentId}
                >
                  <option value="null">none</option>
                  {categoryOptions}
                </TextField>

                <TextField
                  classes={{
                    root: classes.textFieldRoot
                  }}
                  fullWidth
                  select
                  label="Element Type"
                  onChange={handleChange}
                  name="elementType"
                  SelectProps={{
                    native: true
                  }}
                  defaultValue={values.elementType}
                >
                  <option>MENU_ITEM</option>
                  <option>MODIFIER</option>
                  <option>INGREDIENT</option>
                  <option>COMBO</option>
                  <option>ORGANIZATIONAL</option>
                </TextField>

                <Space value="one-and-half" />

                <TextField
                  classes={{
                    root: classes.textFieldRoot
                  }}
                  fullWidth
                  select
                  label="Upsell Category"
                  onChange={handleChange}
                  name="upsellCategory"
                  SelectProps={{
                    native: true
                  }}
                  defaultValue={values.upsellCategory}
                >
                  <option value="''">none</option>
                  {categoryOptions}
                </TextField>

                <Space value="one" />

                <EditUpsellElements
                  allOptions={[]}
                  unsavedOrder={values.upsellElements}
                  updateOrder={upsellElements =>
                    setValues({ ...values, upsellElements })
                  }
                />

                <Space value="one" />

                <TextField
                  classes={{
                    root: classes.textFieldRoot
                  }}
                  fullWidth
                  label="Ask Hint"
                  type="text"
                  name="askHint"
                  helperText={
                    <span>
                      eg: What (
                      <em style={{ textDecoration: 'underline' }}>type of</em>,{' '}
                      <em style={{ textDecoration: 'underline' }}>flavor of</em>
                      , etc) X would you like?
                    </span>
                  }
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.askHint}
                />

                <Space value="half" />

                <TextField
                  classes={{
                    root: classes.textFieldRoot
                  }}
                  fullWidth
                  label="Say Hint"
                  type="text"
                  name="sayHint"
                  helperText={
                    <span>
                      eg: I've got a turkey sandwich (
                      <em style={{ textDecoration: 'underline' }}>on</em>,{' '}
                      <em style={{ textDecoration: 'underline' }}>with</em>,
                      etc) whole grain
                    </span>
                  }
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.sayHint}
                />

                <Space value="half" />

                <TextField
                  classes={{
                    root: classes.textFieldRoot
                  }}
                  fullWidth
                  label="Mod Order"
                  type="text"
                  name="modOrder"
                  helperText={
                    <a
                      href="https://github.com/NovoLabs/unum/blob/develop/vox/vox/src/main/scala/vox/prose/README.md#mod_order"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      more info
                    </a>
                  }
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.modOrder}
                />

                <Space value="half" />

                <TextField
                  classes={{
                    root: classes.textFieldRoot
                  }}
                  label="Readback Quantity"
                  type="text"
                  name="readbackQuantity"
                  helperText="The number of elements/categories to readback"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.readbackQuantity}
                />

                {errors.readbackQuantity && touched.readbackQuantity && (
                  <p
                    style={{
                      color: 'red',
                      fontSize: 12,
                      textAlign: 'right'
                    }}
                  >
                    {errors.readbackQuantity}
                  </p>
                )}

                <Space value="one" />

                <div style={{ display: 'flex' }}>
                  <FormGroup style={{ flex: 1 }}>
                    <Space direction="x" value="one-and-half" />

                    <ElementDnd
                      allOptions={get(recursiveData, 'category.elements')}
                      currentOrder={get(category, 'proseHint.readbackOrder')}
                      unsavedOrder={values.readbackOrder}
                      updateOrder={readbackOrder =>
                        setValues({ ...values, readbackOrder })
                      }
                    />
                  </FormGroup>

                  <FormGroup style={{ flex: 1 }}>
                    <Space direction="x" value="one-and-half" />

                    <CategoryDnd
                      allOptions={get(
                        recursiveData,
                        'category.childCategories'
                      )}
                      currentOrder={get(
                        categoryData,
                        'category.proseHint.readbackCategories'
                      )}
                      unsavedOrder={values.readbackCategories}
                      updateOrder={readbackCategories =>
                        setValues({ ...values, readbackCategories })
                      }
                    />
                  </FormGroup>
                </div>
              </form>
            </Fragment>
          )
        }}
      </Formik>
    </Root>
  )
}

EditCategory.propTypes = {
  categoryId: string.isRequired
}

export default withStyles(styles)(EditCategory)
