import React, { Component } from 'react'
import { array, func } from 'prop-types'
import Autosuggest from 'react-autosuggest'
import IconButton from '@material-ui/core/IconButton'
import { MdClear } from 'react-icons/md'
import TextField from '@material-ui/core/TextField'
import Fuse from 'fuse.js'
import Paper from '@material-ui/core/Paper'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { withStyles } from '@material-ui/core/styles'

/**
 * CategoryAutosuggest accepts an array of Categories and provides users with
 * auto-complete functionality on lookup name or display name.
 */
class CategoryAutosuggest extends Component {
  state = {
    value: '',
    suggestions: []
  }

  static propTypes = {
    /**
     * An array of Menu Categories.
     */
    menuCategories: array.isRequired,
    /**
     * A callback to update the active category of CategoryManager.
     */
    updateActiveCategory: func
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    })
  }

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    })
  }

  // updates state with the current value of the input
  handleChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    })
  }

  // getSuggestions returns any category(s) whose lookup name or display name matches the input value
  getSuggestions = value => {
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length

    const fuzzyOptions = {
      shouldSort: true,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ['categoryId', 'lookupName', 'displayName'],
      threshold: 0.2
    }

    const fuse = new Fuse(this.props.menuCategories, fuzzyOptions)

    if (inputLength === 0) return []

    return fuse.search(inputValue)
  }

  getSuggestionValue = suggestion => {
    return suggestion.displayName
  }

  renderInput = inputProps => {
    const { classes, ref, value, ...rest } = inputProps

    return (
      <div style={{ display: 'flex' }}>
        <TextField
          fullWidth
          label="Search"
          InputProps={{
            inputRef: ref,
            classes: {
              input: classes.input
            },
            value,
            ...rest
          }}
        />
        <IconButton
          disabled={value.length === 0}
          onClick={() => this.setState({ value: '' })}
        >
          <MdClear />
        </IconButton>
      </div>
    )
  }

  /*
   * renderSuggestion() is called with the suggestion as param 1 and an object containing query/isHighlighted attributes
   * as part of 2nd param.
   */
  renderSuggestion = (suggestion, { query, isHighlighted }) => {
    return (
      <ListItem
        onClick={() => this.props.updateActiveCategory(suggestion.categoryId)}
        button
      >
        <ListItemText
          primary={suggestion.displayName}
          secondary={suggestion.lookupName}
        />
      </ListItem>
    )
  }

  /*
   * renderSuggestionsContainer() called with an options object that has containerProps and children attributes
   */
  renderSuggestionsContainer = options => {
    const { containerProps, children } = options
    const { classes } = this.props

    return (
      <React.Fragment>
        {children && (
          <Paper
            classes={{ root: classes.paperRoot }}
            {...containerProps}
            square
          >
            {children}
          </Paper>
        )}
      </React.Fragment>
    )
  }

  render() {
    const { suggestions } = this.state
    const { classes } = this.props

    return (
      <Autosuggest
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion
        }}
        renderInputComponent={this.renderInput}
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        renderSuggestionsContainer={this.renderSuggestionsContainer}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={{
          classes,
          placeholder: 'category lookupName/displayName...',
          value: this.state.value,
          onChange: this.handleChange
        }}
      />
    )
  }
}

const styles = theme => {
  return {
    container: {
      position: 'relative'
    },
    paperRoot: {
      overflowY: 'scroll',
      maxHeight: 230
    },
    suggestionsContainerOpen: {
      position: 'absolute',
      zIndex: 1,
      marginTop: theme.spacing(),
      left: 0,
      right: 0
    },
    suggestion: {
      display: 'block'
    },
    suggestionsList: {
      margin: 0,
      padding: 0,
      listStyleType: 'none'
    }
  }
}

export default withStyles(styles, { withTheme: true })(CategoryAutosuggest)
