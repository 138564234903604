import React from 'react'
import v from 'voca'
import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import { object, string } from 'prop-types'
import { MdNavigateBefore } from 'react-icons/md'
import { compareUsingKey, renderFallback, uniqueOn, ifNil } from 'utils'
import { FixedSizeList } from 'react-window'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import Space from 'shared/components/Space'
import { useQuery } from 'react-apollo'
import { breakPoints } from 'shared/breakPoints'
import GET_ELEMENT from 'pages/menu/graphql/queries/getElement'

/**--------------------- util fns ---------------------*/
// takes a graphql element, returns its' display name and ID in a Typography component
const ElementToTypography = ({ element }) => (
  <Typography variant="body1" align="right">
    {element.displayName} ({element.elementId})
  </Typography>
)

/**--------------------- styles/styled components  ---------------------*/
const ElementHeader = styled.div.withConfig({ displayName: 'ElementHeader' })`
  background-color: #fff;
  display: flex,
  justify-content: center;
  padding: 16px 0px;
  position: fixed;
  width: 100%;
  top: 16;
  z-index: 1;

  @media ${breakPoints.muiLarge} {
    width: calc(100% - 265px);
  }
`

const ButtonContainer = styled.div.withConfig({
  displayName: 'ButtonContainer'
})`
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
  position: fixed;
  width: 100%;
  z-index: 1;

  @media ${breakPoints.muiLarge} {
    width: calc(100% - 265px);
  }
`

const Root = styled.div.withConfig({ displayName: 'ViewElement' })`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`

const ElementAttributeList = styled.div.withConfig({
  displayName: 'ElementAttributeList'
})`
  margin: 0 auto;
  padding: 0 32px;

  @media ${breakPoints.muiLarge} {
    padding: 0;
    width: 1000px;
  }
`

const ElementAttribute = styled.div.withConfig({
  displayName: 'ElementAttribute'
})`
  align-items: center;
  display: flex;
  justify-content: flex-start;
`

const styles = theme => ({
  buttonRoot: {
    color: 'var(--novo-light-gray)'
  },
  btnMinWidth: {
    minWidth: theme.spacing()
  }
})

/**--------------------- main component ---------------------*/
const ViewElement = ({ elementId, back, edit }) => {
  const { data, error, loading } = useQuery(GET_ELEMENT, {
    fetchPolicy: 'network-only',
    variables: { elementId }
  })

  /**--------------------- handle loading and error states here ---------------------*/
  if (loading)
    return (
      <Root>
        <CircularProgress />
      </Root>
    )

  if (error) throw new Error(error)

  /**--------------------- to be rendered when graphql query succeeds ---------------------*/
  const {
    category,
    comboOnly,
    availability: { unavailable: u, available: a },
    displayName,
    displayPlural,
    elementType,
    lookupName,
    speechHints,
    tags,
    owners,
    parts,
    price,
    posId
  } = data.element

  const unavailable = u.sort((a, b) => compareUsingKey(a, b, 'name'))
  const available = a.sort((a, b) => compareUsingKey(a, b, 'name'))

  return (
    <div>
      <ButtonContainer>
        <Button
          disableRipple
          onClick={back}
          style={{ textTransform: 'capitalize' }}
        >
          <Typography
            style={{ color: 'var(--novo-light-gray)' }}
            variant="subtitle1"
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <MdNavigateBefore />
              Elements
            </div>
          </Typography>
        </Button>

        <Button
          color="primary"
          disableRipple
          onClick={edit}
          style={{ textTransform: 'capitalize' }}
        >
          <Typography color="inherit" variant="subtitle1">
            Edit
          </Typography>
        </Button>
      </ButtonContainer>

      <Space value="two" />
      <Space value="half" />

      <ElementHeader>
        <Typography
          align="center"
          variant="h4"
          style={{ fontWeight: 600, textAlign: 'center' }}
        >
          {displayName}
        </Typography>
      </ElementHeader>

      <Space value="three" />
      <Space value="four" />

      <ElementAttributeList>
        <div style={{ display: 'flex' }}>
          <Typography variant="h6" style={{ flex: 1 }}>
            Element Information
          </Typography>

          <div style={{ flex: 1 }}>
            <ElementAttribute>
              <Typography variant="body1">
                <span style={{ fontFamily: 'Pangram Medium' }}>
                  Display Name:
                </span>{' '}
                {displayName}
              </Typography>
            </ElementAttribute>

            <Space value="half" />

            <ElementAttribute>
              <Typography variant="body1">
                <span style={{ fontFamily: 'Pangram Medium' }}>Category:</span>{' '}
                {category.displayName ? category.displayName : 'none'}{' '}
                {category && `(${category.categoryId})`}
              </Typography>
            </ElementAttribute>

            <Space value="half" />

            <ElementAttribute>
              <Typography variant="body1">
                <span style={{ fontFamily: 'Pangram Medium' }}>
                  Display Name Plural:
                </span>{' '}
                {renderFallback(displayPlural, 'none')}
              </Typography>
            </ElementAttribute>

            <Space value="half" />

            <ElementAttribute>
              <Typography variant="body1">
                <span style={{ fontFamily: 'Pangram Medium' }}>
                  Lookup Name:
                </span>{' '}
                {renderFallback(lookupName, 'none')}
              </Typography>
            </ElementAttribute>

            <Space value="half" />

            <ElementAttribute>
              <Typography variant="body1">
                <span style={{ fontFamily: 'Pangram Medium' }}>
                  Element Type:
                </span>{' '}
                {elementType}
              </Typography>
            </ElementAttribute>

            <Space value="half" />

            <ElementAttribute>
              <Typography variant="body1">
                <span style={{ fontFamily: 'Pangram Medium' }}>
                  Element ID:
                </span>{' '}
                {elementId}
              </Typography>
            </ElementAttribute>

            <Space value="half" />

            <ElementAttribute>
              <Typography variant="body1">
                <span style={{ fontFamily: 'Pangram Medium' }}>
                  Combo Only:
                </span>{' '}
                {comboOnly ? 'Yes' : 'No'}
              </Typography>
            </ElementAttribute>

            <Space value="half" />

            <ElementAttribute>
              <Typography variant="body1">
                <span style={{ fontFamily: 'Pangram Medium' }}>Parts:</span>{' '}
                {parts}
              </Typography>
            </ElementAttribute>

            <Space value="half" />

            <ElementAttribute>
              <Typography variant="body1">
                <span style={{ fontFamily: 'Pangram Medium' }}>Price:</span> $
                {(price / 100).toFixed(2)}
              </Typography>
            </ElementAttribute>

            <Space value="half" />

            <ElementAttribute>
              <Typography variant="body1">
                <span style={{ fontFamily: 'Pangram Medium' }}>Tags:</span>{' '}
                {tags.length}
              </Typography>
            </ElementAttribute>

            <Space value="half" />

            <ElementAttribute>
              <div>
                <Typography variant="body1">
                  <span style={{ fontFamily: 'Pangram Medium' }}>
                    Custom Hints:
                  </span>{' '}
                </Typography>
                <div style={{ border: '1px solid rgba(187, 187, 187, .4)', minWidth: 440, minHeight: 48 }}>
                  {(ifNil(speechHints, []).join(", "))}
                </div>
              </div>
            </ElementAttribute>
          </div>
        </div>

        <Space value="two" />

        <Divider />

        <Space value="two" />

        <div style={{ display: 'flex' }}>
          <Typography variant="h6" style={{ flex: 1 }}>
            Availability
          </Typography>

          <div style={{ flex: 1 }}>
            <Typography
              variant="body1"
              style={{ fontFamily: 'Pangram Medium' }}
            >
              Available{' '}
              <span
                style={{ fontFamily: 'Pangram Regular' }}
              >{`(${available.length})`}</span>
              :
            </Typography>

            <Space value="half" />

            <FixedSizeList
              height={200}
              width={440}
              style={{
                border: '1px solid rgba(187, 187, 187, .4)',
                borderRadius: '4px',
                overscrollBehavior: 'contain'
              }}
              itemCount={available.length}
              itemSize={86}
            >
              {({ index, style }) => {
                const l = available[index]
                return (
                  <ListItem style={{ ...style }}>
                    <ListItemText
                      primary={`${v.titleCase(l.name, ["'"])} - #${
                        l.locationId
                      }`}
                      secondary={v.lowerCase(l.storeType)}
                    />
                  </ListItem>
                )
              }}
            </FixedSizeList>

            <Space value="one-and-half" />

            <Typography
              variant="body1"
              style={{ fontFamily: 'Pangram Medium' }}
            >
              Not Available{' '}
              <span
                style={{ fontFamily: 'Pangram Regular' }}
              >{`(${unavailable.length})`}</span>
              :
            </Typography>

            <Space value="half" />

            <FixedSizeList
              height={200}
              width={440}
              style={{
                border: '1px solid rgba(187, 187, 187, .4)',
                borderRadius: '4px',
                overscrollBehavior: 'contain'
              }}
              itemCount={unavailable.length}
              itemSize={86}
            >
              {({ index, style }) => {
                const l = unavailable[index]

                return (
                  <ListItem style={{ ...style, marginBottom: 8 }}>
                    <ListItemText
                      primary={`${v.titleCase(l.name)} - #${l.locationId}`}
                      secondary={v.lowerCase(l.storeType)}
                    />
                  </ListItem>
                )
              }}
            </FixedSizeList>
          </div>
        </div>

        <Space value="two" />

        <Divider />

        <Space value="two" />

        <div style={{ display: 'flex' }}>
          <Typography variant="h6" style={{ flex: 1 }}>
            POS
          </Typography>

          <div style={{ flex: 1 }}>
            <ElementAttribute>
              <div style={{ maxWidth: 350, display: 'flex', flexWrap: 'wrap' }}>
                <span style={{ fontFamily: 'Pangram Medium' }}>ID(s):</span>{' '}
                <Space direction="x" value="half" />
                {renderFallback(
                  posId.map((id, idx) => (
                    <React.Fragment key={id}>
                      <span>{id}</span>
                      {idx !== posId.length - 1 && (
                        <>
                          , <Space direction="x" value="half" />
                        </>
                      )}
                    </React.Fragment>
                  )),
                  'none'
                )}
              </div>
            </ElementAttribute>
          </div>
        </div>

        <Space value="two" />

        <Divider />

        <Space value="two" />

        <div style={{ display: 'flex' }}>
          <Typography variant="h6" style={{ flex: 1 }}>
            Owned By Elements (via component)
          </Typography>

          <div style={{ flex: 1 }}>
            <ElementAttribute>
              <div>
                {uniqueOn(
                  owners.sort((a, b) =>
                    compareUsingKey(a, b, 'owner.displayName')
                  ),
                  'owner.elementId'
                ).map(e => (
                  <ElementToTypography
                    key={e.owner.elementId}
                    element={e.owner}
                  />
                ))}
              </div>
            </ElementAttribute>
          </div>
        </div>
      </ElementAttributeList>

      <Space value="four" />
      <Space value="three" />
    </div>
  )
}

ViewElement.propTypes = {
  /**
   * Classes defined by the material-ui withStyles HOC that wraps ViewElement.
   */
  classes: object,
  /**
   * The elementId of the Element to get data for.
   */
  elementId: string.isRequired
}

export default withStyles(styles, { withTheme: true })(ViewElement)
