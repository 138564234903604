import React, { useState } from 'react'
import styled from 'styled-components'
import get from 'lodash.get'
import { isNil, removeValue, contains } from 'utils'
import FormLabel from '@material-ui/core/FormLabel'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import AddUpsellElementsDialog from 'pages/menu/components/categories/AddUpsellElementsDialog'
import { MdAdd, MdClose } from 'react-icons/md'
import { useQuery } from 'react-apollo'
import { useConceptLocationsFilter } from 'shared/hooks'
import GET_ELEMENTS from 'pages/menu/graphql/queries/getElements'

/**---------- styles and styled components ----------*/
const Root = styled.div.withConfig({ displayName: 'EditUpsellElements' })`
  min-height: 100px;
`

const styles = { buttonCritical: { color: 'var(--color-utility-critical)' } }

/**---------- complementary components ----------*/
const UnstyledActiveUpsellElement = ({ element, classes, remove }) => (
  <ListItem dense disableGutters>
    <ListItemText>{element.displayName}</ListItemText>
    <ListItemSecondaryAction>
      <IconButton
        color="primary"
        classes={{
          colorPrimary: classes.buttonCritical
        }}
        onClick={() => remove(element.elementId)}
      >
        <MdClose />
      </IconButton>
    </ListItemSecondaryAction>
  </ListItem>
)

const ActiveUpsellElement = withStyles(styles)(UnstyledActiveUpsellElement)

/**---------- util fns ----------*/
const getElementId = e => e.elementId

/**---------- main component ----------*/
const EditUpsellElements = ({ allOptions, unsavedOrder, updateOrder }) => {
  // const [blockScrolling, setBlockScrolling] = useState(false)
  const [addingActiveOptions, setAddingActiveOptions] = useState(false)
  const toggleAddingActiveOptions = () =>
    setAddingActiveOptions(!addingActiveOptions)
  const {
    data: elementsData,
    error: elementsError,
    loading: elementsLoading
  } = useQuery(GET_ELEMENTS, {
    variables: { conceptLocationsFilter: useConceptLocationsFilter() }
  })

  if (elementsError)
    return (
      <Root>
        <Typography variant="body2">
          error loading elements for concept
        </Typography>
      </Root>
    )

  if (elementsLoading || !elementsData)
    return (
      <Root>
        <div
          style={{
            minHeight: 48,
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <FormLabel component="label">Upsell Elements</FormLabel>
        </div>
      </Root>
    )

  const elements = get(elementsData, 'elements')
  const verifiedIds = unsavedOrder.filter(id =>
    contains(elements, id, getElementId)
  )

  const activeUpsellElements = !isNil(elements)
    ? verifiedIds.map(id => {
        const element = elements.find(e => e.elementId === id)
        return (
          <ActiveUpsellElement
            key={id}
            element={element}
            remove={toBeRemoved =>
              updateOrder(removeValue(verifiedIds, toBeRemoved))
            }
          />
        )
      })
    : []

  return (
    <Root>
      <div
        style={{
          minHeight: 48,
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <FormLabel component="label">Upsell Elements</FormLabel>
        <IconButton color="primary" onClick={toggleAddingActiveOptions}>
          <MdAdd />
        </IconButton>
      </div>

      <AddUpsellElementsDialog
        options={elements.filter(e => !contains(verifiedIds, e.elementId))}
        open={addingActiveOptions}
        submit={ids => updateOrder([...verifiedIds, ...ids])}
        toggleOpen={toggleAddingActiveOptions}
      />

      <List style={{ minWidth: 350 }}>{activeUpsellElements}</List>
    </Root>
  )
}

export default EditUpsellElements
