import React from 'react'
import { string, func } from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'

const Alert = ({ message, cancel, confirm, title, open }) => {
  return (
    <Dialog open={open} onClose={cancel}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        {message && <DialogContentText>{message}</DialogContentText>}
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel}>cancel</Button>
        <Button onClick={confirm} color="primary">ok</Button>
      </DialogActions>
    </Dialog>
  )
}

Alert.propTypes = {
  message: string.isRequired,
  cancel: func.isRequired,
  confirm: func.isRequired,
  title: string
}

export default Alert
