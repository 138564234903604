import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { bool, string } from 'prop-types'
import Button from '@material-ui/core/Button'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import StyledLink from 'shared/components/StyledLink'

/**
 * ConceptTile renders a clickable button that allows users to select a Concept.
 */
const ConceptTile = props => {
  const { classes, active, name, id, match: { app, section } } = props

  const parts = name.split(' ')

  let initials = ''
  
  const to = `/c/${id}/${app}/${section}`

  if (parts.length > 1) {
    initials = `${parts[0].charAt(0)}${parts[1].charAt(0)}`
  } else {
    initials = parts[0].charAt(0)
  }

  if (active) {
    return (
      <ConceptTileContainer>
        <ActiveTileIndicator />

        <div>
          <StyledLink to={to}>
            <Button
              variant="contained"
              classes={{
                root: classes.activeConceptButton
              }}
            >
              <Typography variant="h6">{initials}</Typography>
            </Button>
          </StyledLink>
        </div>
      </ConceptTileContainer>
    )
  }

  return (
    <ConceptTileContainer>
      <div>
        <StyledLink to={to}>
          <Button
            variant="contained"
            classes={{
              root: classes.inactiveConceptButton
            }}
          >
            <Typography variant="h6">{initials}</Typography>
          </Button>
        </StyledLink>
      </div>
    </ConceptTileContainer>
  )
}

ConceptTile.propType = {
  /**
   * If this is the active concept, render an indicator to the left of the tile.
   */
  active: bool,
  /**
   * URL where logo is stored.
   */
  logo: string,
  /**
   * Name of concept.
   */
  name: string.isRequired
}

const ConceptTileContainer = styled.div.withConfig({
  displayName: 'ConceptTileContainer'
})`
  display: flex;
  justify-content: center;
  margin: 16px 0px;
  margin-bottom: 8px;
  width: 100%;
`

const ActiveTileIndicator = styled.div.withConfig({
  displayName: 'ActiveTileIndicator'
})`
  background-color: var(--novo-light-gray);
  border-radius: 2px;
  height: 40px;
  margin-left: -26px;
  width: 12px;
`

const styles = theme => ({
  activeConceptButton: {
    backgroundColor: 'white',
    color: '#303E4D',
    height: 40,
    marginLeft: 14,
    minHeight: 40,
    minWidth: 40,
    width: 40
  },
  inactiveConceptButton: {
    backgroundColor: 'rgba(255, 255, 255, .6)',
    color: '#303E4D',
    height: 40,
    minHeight: 40,
    minWidth: 40,
    width: 40
  }
})

export default withStyles(styles, { withTheme: true })(ConceptTile)
