import React from 'react'

const ErrorMessage = ({ message }) => (
  <p
    style={{
      color: 'crimson',
      fontSize: '.75rem',
      marginTop: 0,
      marginBottom: 16
    }}
  >
    {message}
  </p>
)

export default ErrorMessage
