import React, { Fragment } from 'react'
import { array } from 'prop-types'
import styled from 'styled-components'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'

const CategoryList = props => {
  const { categories, updateActiveCategory } = props

  const categoriesGroupedByFirstLetter = {}

  const sortCategoriesByDisplayName = array => {
    return array.sort((a, b) => {
      if (a.displayName.toLowerCase() < b.displayName.toLowerCase()) {
        return -1
      } else if (b.displayName.toLowerCase() < a.displayName.toLowerCase()) {
        return 1
      } else return 0
    })
  }

  // populate categoriesGroupedByFirstLetter
  categories.forEach(c => {
    if (categoriesGroupedByFirstLetter[`${c.displayName[0]}`] === undefined) {
      categoriesGroupedByFirstLetter[`${c.displayName[0]}`] = [c]
    } else categoriesGroupedByFirstLetter[`${c.displayName[0]}`].push(c)
  })

  // sort the categories in each group alphabetically
  for (const letter in categoriesGroupedByFirstLetter) {
    categoriesGroupedByFirstLetter[`${letter}`] = sortCategoriesByDisplayName(
      categoriesGroupedByFirstLetter[`${letter}`]
    )
  }

  const categoryGroups = Object.keys(categoriesGroupedByFirstLetter).sort()

  const sections = categoryGroups.map(g => {
    const categories = categoriesGroupedByFirstLetter[`${g}`].map(c => {
      return (
        <ListItem
          button
          key={c.categoryId}
          onClick={() => updateActiveCategory(c.categoryId)}
        >
          <ListItemText
            primary={c.displayName}
            secondary={`${c.elementType}(S)`}
          />
        </ListItem>
      )
    })

    return (
      <Fragment key={g}>
        <ListSubheader style={{ zIndex: 0 }}>{g.toUpperCase()}</ListSubheader>
        <Divider light style={{ margin: '0 16px' }} />
        {categories}
      </Fragment>
    )
  })

  return (
    <CategoryListRoot>
      <List disablePadding>{sections}</List>
    </CategoryListRoot>
  )
}

CategoryList.propTypes = {
  /*
   * A collection of MenuCategories.
   */
  categories: array
}

const CategoryListRoot = styled.div.withConfig({
  displayName: 'CategoryListRoot'
})`
  padding: 0;
  margin: 0;
`

export default CategoryList
