import React, { useState, useEffect, useRef } from 'react'
import get from 'lodash.get'
import isequal from 'lodash.isequal'
import { isNil } from 'utils'
import styled from 'styled-components'
import { useConceptLocations } from 'shared/hooks'
import { useQuery } from 'react-apollo'
import { breakPoints } from 'shared/breakPoints'
import Typography from '@material-ui/core/Typography'
import Space from 'shared/components/Space'
import CategoryAutosuggest from 'pages/menu/components/categories/CategoryAutosuggest'
import CategoryList from 'pages/menu/components/categories/CategoryList'
import ViewCategory from 'pages/menu/components/categories/ViewCategory'
import EditCategory from 'pages/menu/components/categories/EditCategory'
import GET_CATEGORIES from 'pages/menu/graphql/queries/getCategories'

/**---------- styled components ----------*/
const Root = styled.div.withConfig({ displayName: 'CategoryManager' })`
  flex: 1;
`

const StickyContainer = styled.div.withConfig({
  displayName: 'StickyContainer'
})`
  background-color: white;
  flex: 1;
  padding: 16px;
  position: fixed;
  top: ${({ top }) => (top ? top : 'initial')};
  width: 100%;
  z-index: 1;

  @media ${breakPoints.muiLarge} {
    width: calc(100% - 265px);
  }
`

/**---------- complementary components ----------*/
const Title = () => (
  <Typography align="center" variant="h6">
    Categories
  </Typography>
)
const Loading = () => (
  <Root>
    <Title />
  </Root>
)

/**---------- main component ----------*/
const CategoryManager = () => {
  const [editing, setEditing] = useState(false)
  const [activeCategoryId, setActiveCategoryId] = useState()
  const { context: conceptLocationsContext, filter } = useConceptLocations()
  const { data, error, loading } = useQuery(GET_CATEGORIES, {
    variables: {
      conceptId: get(filter, 'conceptId')
    },
    skip: get(filter, 'conceptId') === null
  })
  const clearActiveCategory = () => setActiveCategoryId(null)

  // references hold their value in between renders, for the life of the component
  const conceptLocationsReference = useRef(filter)

  useEffect(() => {
    if (!isequal(filter, conceptLocationsReference.current)) {
      conceptLocationsReference.current = filter
      clearActiveCategory()
      setEditing(false)
    }
  }, [filter])

  if (isNil(get(conceptLocationsContext, 'activeConceptId'))) return <Root />
  if (error) throw new Error(error)
  if (loading || !data) return <Loading />

  const { categories } = data

  return (
    <Root>
      {!activeCategoryId && (
        <>
          <StickyContainer top="64px">
            <Title />

            <CategoryAutosuggest
              menuCategories={categories}
              updateActiveCategory={setActiveCategoryId}
            />
          </StickyContainer>

          <Space value="four" />
          <Space value="three" />
          <Space value="half" />

          <CategoryList
            categories={categories}
            updateActiveCategory={setActiveCategoryId}
          />
        </>
      )}

      {activeCategoryId && !editing && (
        <ViewCategory
          back={clearActiveCategory}
          editCategory={() => setEditing(true)}
          categoryId={activeCategoryId}
        />
      )}

      {activeCategoryId && editing && (
        <EditCategory
          categoryId={activeCategoryId}
          exit={() => setEditing(false)}
        />
      )}
    </Root>
  )
}

export default CategoryManager
