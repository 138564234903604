import React, { useState } from 'react'
import { useQuery } from 'react-apollo'
import { GET_MENUS } from 'pages/menu/graphql/queries/menu'
import { Space } from 'shared/components'
import { titleCase } from 'utils'
import { decode } from 'utils/graphql'
import { useConceptLocationsContext } from 'shared/hooks'
import EditMenu from 'pages/menu/components/EditMenu'
import CreateMenu from 'pages/menu/components/CreateMenu'
import ErrorMessage from 'pages/menu/components/ErrorMessage'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import Paper from '@material-ui/core/Paper'
import ViewMenu from 'pages/menu/components/ViewMenu'

const MenuManager = props => {
  let concept

  const { activeConceptId, concepts } = useConceptLocationsContext()
  const [activeMenu, setActiveMenu] = useState(null)

  if (concepts) concept = concepts.find(c => c.id === activeConceptId)

  const { data, error, loading } = useQuery(GET_MENUS, {
    variables: {
      concept: activeConceptId
    },
    fetchPolicy: 'network-only'
  })

  if (error)
    return <ErrorMessage message={`error loading menus: ${error.message}`} />
  else if (loading || !data) return null

  const columns = ['Name', 'Default', 'ID', 'Locations']

  return (
    <div style={{ padding: '0px 24px' }}>
      {activeMenu && (
        <ViewMenu close={() => setActiveMenu(null)} menu={activeMenu} conceptId={activeConceptId} />
      )}

      <Space direction="y" value="two" />

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <CreateMenu activeConceptId={activeConceptId} concept={concept} />
      </div>
      <Space direction="y" value="one" />
      <TableContainer component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell />
              {columns.map(c => (
                <TableCell key={c}>{c}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.menus.map((m, idx) => (
              <TableRow
                onClick={() => setActiveMenu(m)}
                style={{
                  fontStyle: m.default ? 'italic' : 'normal'
                }}
                key={idx}
                hover
              >
                <TableCell onClick={e => e.stopPropagation()} padding="checkbox">
                  <EditMenu menu={m} activeConceptId={activeConceptId} />
                </TableCell>
                <TableCell style={{ maxWidth: 120 }}>
                  {titleCase(m.name)}
                </TableCell>
                <TableCell>{m.default.toString()}</TableCell>
                <TableCell>{decode(m.id).id}</TableCell>
                <TableCell>{m.locations.length}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default MenuManager
